<template>
  <div id="app">
    <Index/>
  </div>
</template>

<script>
import Index from './components/Index';

export default {
  name: 'app',

  components: {
    Index
  }
}
</script>

<style lang="scss">
body, html {
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
