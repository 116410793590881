import {required, email} from 'vuelidate/lib/validators';

const // Custom validators
    isName = value => (/^([A-Za-z\s]+)$/i.test(value)),
    isMobilePhone = value => (/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(value));

export default {
    form: {
        FirstName: {
            required,
            isName,
        },
        MiddleName: {
            validateIf: name => {
                return name ? isName(name) : true;
            },
        },
        LastName: {
            required,
            isName,
        },
        Email: {
            email,
            required
        },
        Phone: {
            isMobilePhone,
            required
        }
    }
}
