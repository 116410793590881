<template>
  <div class="combobox" :class="{ 'form-group--error': validator && validator.$error }" ref="combobox">
    <label class="combobox__label" v-if="label"> {{ label }}</label>
    <div class="combobox__select"
         :style="{
                  '--min-width'     : minWidth,
                  '--max-height'    : maxHeight,
                  '--bg-selected'   : bgSelected,
                  '--bg-options'    : bgOptions,
                  '--color-selected': colorSelected,
                  '--color-options' : colorOptions,
                  '--border'        : border,
                  '--height'        : height,
                  '--border-color'  : borderColor
                }">
      <div class="combobox__selected" @click="deploy" :class="{'active': deployed}">
        <span v-if="option !== null && option !== undefined">{{ valueText ? option[valueText] : option }}</span>
        <span v-else>{{ text }}</span>
        <span v-if="isLoading">
          <Spinner color="#221ecd" size="1.4rem"/>
        </span>
        <i class="ez-icon-caret-down" v-else/>
      </div>
      <div v-if="deployed" class="combobox__options active">
        <div class="combobox__filter" v-if="showSearch">
          <input type="search" :placeholder="$t('search')" v-model="search">
        </div>
        <ul class="combobox__list ez-slim-scroll" v-if="optionsFiltered && optionsFiltered.length > 0">
          <li @click="updateSelect(option)" v-for="option in optionsFiltered"
              :value='valueKey ? option[valueKey] : option'
              :key="valueKey ? option[valueKey] : option">{{ valueText ? option[valueText] : option }}
          </li>
        </ul>
        <div class="no-element" v-else>{{ $t('no_element') }}</div>
      </div>
    </div>
    <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>
    <div v-if="validator && validator.$error">
      <span class="has-error animated headShake" v-if="!validator.required">{{ $t('field required') }}</span>
    </div>
  </div>
</template>

<script>
import {uniqBy, uniq} from 'lodash';
import Spinner from "./Spinner";

export default {
  name: "ComboBox",
  components: {Spinner},
  props: {
    text: {type: String, default: 'N/A'},
    options: {type: Array},
    valueKey: {type: String, default: null},
    valueText: {type: String, default: null},
    change: {type: Function},
    value: [String, Number],
    validator: null,
    disabled: {type: Boolean, default: false},
    isLoading: {type: Boolean, default: false},
    label: {type: String},
    error: {type: String},
    bgSelected: {type: String, default: '#fff'},
    bgOptions: {type: String, default: '#fff'},
    colorSelected: {type: String, default: '#8798AD'},
    colorOptions: {type: String, default: '#8798AD'},
    borderColor: {type: String, default: '#E7E8F2'},
    border: {type: String, default: '1px'},
    height: {type: String, default: '2.5rem'},
    minWidth: {type: String, default: '100%'},
    maxHeight: {
      type: String,
      default: function () {
        const height = this.height.replace(/rem|em|px/i, ''),
            normalizedHeight = this.options.length > 5 ? height * 6 : (height * 5) + 1;
        return `${normalizedHeight}rem`;
      }
    },
  },

  data() {
    return {
      deployed: false,
      option: null,
      search: '',
    }
  },

  watch: {
    value() {
      if (this.value !== null && this.value !== undefined) {
        if (this.valueKey) {
          this.option = this.options.find(o => o[this.valueKey].toString() === this.value.toString()) || null;
        } else
          this.option = this.options.find(o => o === this.value) || null;
      } else {
        this.option = null;
      }
    },

    deployed(value) {
      if (!value) this.search = '';
    }
  },

  created() {
    document.addEventListener("click", this.documentClick);
  },

  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },

  computed: {
    showSearch() {
      return this.options.length > 5;
    },

    optionsFiltered() {
      const unique = this.valueKey ? uniqBy(this.options, this.valueKey) : uniq(this.options);

      if (this.search) {
        return unique.filter(option => {
          return this.search
              .toLowerCase()
              .split(' ')
              .every(search => {
                const optionText = this.valueText ? option[this.valueText] : option;
                return optionText.toLowerCase().includes(search);
              });
        });
      }

      return unique;
    }
  },

  mounted() {
    if (this.value) {
      if (this.valueKey) {
        this.option = this.options.find(o => o[this.valueKey].toString() === this.value.toString()) || null;
      } else
        this.option = this.options.find(o => o === this.value) || null;
    }
  },

  methods: {
    deploy() {
      if (this.disabled || this.isLoading) return;
      this.deployed = !this.deployed;

      if (this.deployed)
        this.$emit('deployed');
    },
    documentClick(e) {
      const el = this.$refs.combobox;
      const target = e.target;

      if (el !== target && el && !el.contains(target)) {
        this.deployed = false;
      }
    },
    updateSelect(option) {
      this.option = option;
      this.$emit('input', this.valueKey ? this.option[this.valueKey] : this.option);
      this.$emit('change', this.valueKey ? this.option[this.valueKey] : this.option);
      if (this.change) this.change(this.valueKey ? this.option[this.valueKey] : this.option);
      this.deployed = false;
    }
  }
}
</script>

<style scoped lang="scss">

.combobox {
  --bg: white;
  --border: 1px;
  position: relative;

  &__select {
    border-radius: 3px;
    min-width: var(--min-width);
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }

  &__selected {
    background: var(--bg-selected);
    border: var(--border) solid var(--border-color);
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: var(--height);
    justify-content: space-between;

    span {
      color: var(--color-selected);
      font-weight: $font-regular;
      padding: 0 10px;
      font-size: .8rem;
    }

    i {
      color: color(bg-black3);
      border-radius: 6px;
      height: 25px;
      width: 25px;
      margin-right: 10px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      border-radius: 3px 3px 0 0;
    }
  }

  &__options {
    background: var(--bg);
    border: 1px solid color(border);
    border-radius: 0 0 3px 3px;
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    max-height: var(--max-height);
    top: var(--height);
    z-index: 999;

    &.active {
      border-top: 0;
      display: flex;
      flex-direction: column;
    }

    .no-element {
      width: 100%;
      color: color(bg-black3);
      font-size: .8rem;
      text-align: center;
      height: var(--height);
      line-height: var(--height);
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    height: var(--height);

    input {
      border: none;
      color: var(--color-selected);
      height: var(--height);
      padding: 0 10px;
      box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
      width: 100%;
      font-weight: $font-regular;
      font-size: .8rem;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-selected);
        font-size: .8rem;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--color-selected);
        font-size: .8rem;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--color-selected);
        font-size: .8rem;
      }
    }
  }

  &__list {
    color: var(--color-options);
    width: 100%;
    overflow: auto;
    flex: 1;

    li {
      display: flex;
      align-items: center;
      height: var(--height);
      padding: 0 10px;
      font-size: .8rem;
      width: 100%;

      &:hover {
        background: color(bg-gray3);
        cursor: pointer;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black3);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  select {
    background: var(--bg);
    border: var(--border) solid color(border);
    border-radius: .25rem;
    color: #495057;
    height: 40px;
    font-size: .8rem;
    padding: .3rem .5rem;
    transition: .15s ease-in-out;
    width: 100%;

    &:focus {
      box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
}
</style>

<i18n scoped>
{
  "en": {
    "Invalid field": "Invalid field",
    "Required field": "Required field",
    "Invalid field, select at least one": "Invalid field, must select almost one",
    "Invalid field, must be greater": "Invalid field, must be greater",
    "no_element": "No elements",
    "required_field": "Required field",
    "search": "Search..."
  },
  "es": {
    "Invalid field": "Campo no válido",
    "Required field": "Campo obligatorio",
    "Invalid field, select at least one": "Campo no válido, debe seleccionar al menos una opción",
    "Invalid field, must be greater": "Campo no válido, debe ser mayor",
    "no_element": "No hay elementos",
    "required_field": "Campo obligatorio",
    "search": "Buscar..."
  }
}
</i18n>