export default {
    name: 'terms',
    components: {},
    props: [],
    data() {
        return {
            acceptedTerms: false,
        }
    },
    computed: {},
    mounted() {

    },
    methods: {
        onScrollTerms(e) {
            if (e.target.scrollTop + e.target.clientHeight < (e.target.scrollHeight - 1) || this.acceptedTerms) {
                return;
            }

            this.$emit('termsAccepted', true);
            this.acceptedTerms = true;
        },
    }
}


