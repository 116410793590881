export default {
    FirstNameError() {
        if (!this.$v.form.FirstName.$anyError) return null;
        else if (!this.$v.form.FirstName.required) return "required_field";
        else if (this.$v.form.FirstName.$invalid) return "invalid_field";
        else return null;
    },
    MiddleNameError() {
        if (!this.$v.form.MiddleName.$anyError) return null;
        else if (this.$v.form.MiddleName.$invalid) return "invalid_field";
        else return null;
    },
    LastNameError() {
        if (!this.$v.form.LastName.$anyError) return null;
        else if (!this.$v.form.LastName.required) return "required_field";
        else if (this.$v.form.LastName.$invalid) return "invalid_field";
        else return null;
    },
    EmailError() {
        if (!this.$v.form.Email.$anyError) return null;
        else if (!this.$v.form.Email.required) return "required_field";
        else if (this.$v.form.Email.$invalid) return "invalid_field";
        else return null;
    },
    PhoneError() {
        if (!this.$v.form.Phone.$anyError) return null;
        else if (!this.$v.form.Phone.required) return "required_field";
        else if (this.$v.form.Phone.$invalid) return "invalid_field";
        else return null;
    },
    BirthdayError() {
        if (!this.$v.form.Birthday.$anyError) return null;
        else if (!this.$v.form.Birthday.required) return "required_field";
        else if (this.$v.form.Birthday.$invalid) return "invalid_field";
        else return null;
    },
    SSNError() {
        if (!this.$v.form.SSN.$anyError) return null;
        else if (!this.$v.form.SSN.required) return "required_field";
        else if (this.$v.form.SSN.$invalid) return "invalid_field";
        else return null;
    },
    ITINError() {
        if (!this.$v.form.ITIN.$anyError) return null;
        else if (!this.$v.form.ITIN.required) return "required_field";
        else if (this.$v.form.ITIN.$invalid) return "invalid_field";
        else return null;
    },
    PassportError() {
        if (!this.$v.form.Passport.$anyError) return null;
        else if (!this.$v.form.Passport.required) return "required_field";
        else if (this.$v.form.Passport.$invalid) return "invalid_field";
        else return null;
    },
    DriverLicenseStateError() {
        if (!this.$v.form.DriverLicenseState.$anyError) return null;
        else if (!this.$v.form.DriverLicenseState.required) return "required_field";
        else if (this.$v.form.DriverLicenseState.$invalid) return "invalid_field";
        else return null;
    },
    DriverLicenseError() {
        if (!this.$v.form.DriverLicense.$anyError) return null;
        else if (!this.$v.form.DriverLicense.required) return "required_field";
        else if (this.$v.form.DriverLicense.$invalid) return "invalid_field";
        else return null;
    },
    IdTypeError() {
        if (!this.$v.form.IdType.$anyError) return null;
        else if (!this.$v.form.IdType.required) return "required_field";
        else if (this.$v.form.IdType.$invalid) return "invalid_field";
        else return null;
    },

    AddressError() {
        if (!this.$v.form.Address.$anyError) return null;
        else if (!this.$v.form.Address.required) return "required_field";
        else if (this.$v.form.Address.$invalid) return "invalid_field";
        else return null;
    },
    CityError() {
        if (!this.$v.form.City.$anyError) return null;
        else if (!this.$v.form.City.required) return "required_field";
        else if (this.$v.form.City.$invalid) return "invalid_field";
        else return null;
    },
    StateError() {
        if (!this.$v.form.State.$anyError) return null;
        else if (!this.$v.form.State.required) return "required_field";
        else if (this.$v.form.State.$invalid) return "invalid_field";
        else return null;
    },
    ZipError() {
        if (!this.$v.form.Zip.$anyError) return null;
        else if (!this.$v.form.Zip.required) return "required_field";
        else if (this.$v.form.Zip.$invalid) return "invalid_field";
        else return null;
    },
    MonthlyPaymentError() {
        if (!this.$v.form.MonthlyPayment.$anyError) return null;
        else if (!this.$v.form.MonthlyPayment.required) return "required_field";
        else if (this.$v.form.MonthlyPayment.$invalid) return "invalid_field";
        else return null;
    },
    LivedYearsError() {
        if (!this.$v.form.LivedYears.$anyError) return null;
        else if (!this.$v.form.LivedYears.required) return "required_field";
        else if (this.$v.form.LivedYears.$invalid) return "invalid_field";
        else return null;
    },
    LivedMonthsError() {
        if (!this.$v.form.LivedMonths.$anyError) return null;
        else if (!this.$v.form.LivedMonths.required) return "required_field";
        else if (this.$v.form.LivedMonths.$invalid) return "invalid_field";
        else return null;
    },

    PreviousCountryError() {
        if (!this.$v.form.PreviousAddress.Country.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.Country.required) return "required_field";
        else if (this.$v.form.PreviousAddress.Country.$invalid) return "invalid_field";
        else return null;
    },
    PreviousAddressError() {
        if (!this.$v.form.PreviousAddress.Address.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.Address.required) return "required_field";
        else if (this.$v.form.PreviousAddress.Address.$invalid) return "invalid_field";
        else return null;
    },
    PreviousCityError() {
        if (!this.$v.form.PreviousAddress.City.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.City.required) return "required_field";
        else if (this.$v.form.PreviousAddress.City.$invalid) return "invalid_field";
        else return null;
    },
    PreviousStateError() {
        if (!this.$v.form.PreviousAddress.State.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.State.required) return "required_field";
        else if (this.$v.form.PreviousAddress.State.$invalid) return "invalid_field";
        else return null;
    },
    PreviousZipError() {
        if (!this.$v.form.PreviousAddress.Zip.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.Zip.required) return "required_field";
        else if (this.$v.form.PreviousAddress.Zip.$invalid) return "invalid_field";
        else return null;
    },
    PreviousMonthlyPaymentError() {
        if (!this.$v.form.PreviousAddress.MonthlyPayment.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.MonthlyPayment.required) return "required_field";
        else if (this.$v.form.PreviousAddress.MonthlyPayment.$invalid) return "invalid_field";
        else return null;
    },
    PreviousLivedYearsError() {
        if (!this.$v.form.PreviousAddress.LivedYears.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.LivedYears.required) return "required_field";
        else if (this.$v.form.PreviousAddress.LivedYears.$invalid) return "invalid_field";
        else return null;
    },
    PreviousLivedMonthsError() {
        if (!this.$v.form.PreviousAddress.LivedMonths.$anyError) return null;
        else if (!this.$v.form.PreviousAddress.LivedMonths.required) return "required_field";
        else if (this.$v.form.PreviousAddress.LivedMonths.$invalid) return "invalid_field";
        else return null;
    },

    EmploymentStatusError() {
        if (!this.$v.form.EmploymentStatus.$anyError) return null;
        else if (!this.$v.form.EmploymentStatus.required) return "required_field";
        else return null;
    },
    EmploymentEmployerError() {
        if (!this.$v.form.EmploymentEmployer.$anyError) return null;
        else if (!this.$v.form.EmploymentEmployer.required) return "required_field";
        else if (this.$v.form.EmploymentEmployer.$invalid) return "invalid_field";
        else return null;
    },
    EmploymentTitleError() {
        if (!this.$v.form.EmploymentTitle.$anyError) return null;
        else if (!this.$v.form.EmploymentTitle.required) return "required_field";
        else if (this.$v.form.EmploymentTitle.$invalid) return "invalid_field";
        else return null;
    },
    EmploymentPhoneError() {
        if (!this.$v.form.EmploymentPhone.$anyError) return null;
        else if (!this.$v.form.EmploymentPhone.required) return "required_field";
        else if (this.$v.form.EmploymentPhone.$invalid) return "invalid_field";
        else return null;
    },
    EmploymentYearsError() {
        if (!this.$v.form.EmploymentYears.$anyError) return null;
        else if (!this.$v.form.EmploymentYears.required) return "required_field";
        else if (this.$v.form.EmploymentYears.$invalid) return "invalid_field";
        else return null;
    },
    EmploymentMonthsError() {
        if (!this.$v.form.EmploymentMonths.$anyError) return null;
        else if (!this.$v.form.EmploymentMonths.required) return "required_field";
        else if (this.$v.form.EmploymentMonths.$invalid) return "invalid_field";
        else return null;
    },
    GrossIncomeError() {
        if (!this.$v.form.GrossIncome.$anyError) return null;
        else if (!this.$v.form.GrossIncome.required) return "required_field";
        else if (this.$v.form.GrossIncome.$invalid) return "invalid_field";
        else return null;
    },
    SalaryPerMonthError() {
        if (!this.$v.form.SalaryPerMonth.$anyError) return null;
        else if (this.$v.form.SalaryPerMonth.$invalid) return "invalid_field";
        else return null;
    },

    PreviousEmployerStatusError() {
        if (!this.$v.form.PreviousEmployer.EmploymentStatus.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentStatus.required) return "required_field";
        else return null;
    },
    PreviousEmployerEmployerError() {
        if (!this.$v.form.PreviousEmployer.EmploymentEmployer.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentEmployer.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.EmploymentEmployer.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerTitleError() {
        if (!this.$v.form.PreviousEmployer.EmploymentTitle.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentTitle.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.EmploymentTitle.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerPhoneError() {
        if (!this.$v.form.PreviousEmployer.EmploymentPhone.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentPhone.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.EmploymentPhone.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerYearsError() {
        if (!this.$v.form.PreviousEmployer.EmploymentYears.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentYears.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.EmploymentYears.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerMonthsError() {
        if (!this.$v.form.PreviousEmployer.EmploymentMonths.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.EmploymentMonths.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.EmploymentMonths.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerGrossIncomeError() {
        if (!this.$v.form.PreviousEmployer.GrossIncome.$anyError) return null;
        else if (!this.$v.form.PreviousEmployer.GrossIncome.required) return "required_field";
        else if (this.$v.form.PreviousEmployer.GrossIncome.$invalid) return "invalid_field";
        else return null;
    },
    PreviousEmployerSalaryPerMonthError() {
        if (!this.$v.form.PreviousEmployer.SalaryPerMonth.$anyError) return null;
        else if (this.$v.form.PreviousEmployer.SalaryPerMonth.$invalid) return "invalid_field";
        else return null;
    },

    DownPaymentError() {
        if (!this.$v.form.DownPayment.$anyError) return null;
        else if (this.$v.form.DownPayment.$invalid) return "invalid_field";
        else return null;
    },
    CarMonthlyPaymentError() {
        if (!this.$v.form.CarMonthlyPayment.$anyError) return null;
        else if (this.$v.form.CarMonthlyPayment.$invalid) return "invalid_field";
        else return null;
    },
    TagPlateNumberError() {
        if (!this.$v.form.TagPlateNumber.$anyError) return null;
        else if (!this.$v.form.TagPlateNumber.required) return "required_field";
        else if (this.$v.form.TagPlateNumber.$invalid) return "invalid_field";
        else return null;
    },

    validPersonalInfo() {
        return !(this.FirstNameError || this.LastNameError || this.EmailError || this.PhoneError || this.BirthdayError || this.SSNError || this.ITINError || this.PassportError || this.DriverLicenseError);
    },
    validAddress() {
        return !(this.AddressError || this.CityError || this.StateError || this.ZipError || this.MonthlyPaymentError || this.LivedYearsError || this.LivedMonthsError ||
            this.PreviousAddressError || this.PreviousCityError || this.PreviousStateError || this.PreviousZipError || this.PreviousMonthlyPaymentError || this.PreviousLivedYearsError || this.PreviousLivedMonthsError);
    },
    validIncome() {
        return !(this.EmploymentStatusError || this.EmploymentEmployerError || this.EmploymentTitleError || this.EmploymentPhoneError || this.EmploymentYearsError || this.EmploymentMonthsError || this.GrossIncomeError || this.SalaryPerMonthError ||
            this.PreviousEmployerStatusError || this.PreviousEmployerEmployerError || this.PreviousEmployerTitleError || this.PreviousEmployerPhoneError || this.PreviousEmployerYearsError || this.PreviousEmployerMonthsError || this.PreviousEmployerGrossIncomeError || this.PreviousEmployerSalaryPerMonthError);
    },
    validFinance() {
        return !(this.DownPaymentError || this.CarMonthlyPaymentError || this.TagPlateNumberError);
    },
}