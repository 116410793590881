export default {
    FirstNameError() {
        if (!this.$v.form.FirstName.$anyError) return null;
        else if (!this.$v.form.FirstName.required) return "required_field";
        else if (this.$v.form.FirstName.$invalid) return "invalid_field";
        else return null;
    },
    MiddleNameError() {
        if (!this.$v.form.MiddleName.$anyError) return null;
        else if (this.$v.form.MiddleName.$invalid) return "invalid_field";
        else return null;
    },
    LastNameError() {
        if (!this.$v.form.LastName.$anyError) return null;
        else if (!this.$v.form.LastName.required) return "required_field";
        else if (this.$v.form.LastName.$invalid) return "invalid_field";
        else return null;
    },
    EmailError() {
        if (!this.$v.form.Email.$anyError) return null;
        else if (!this.$v.form.Email.required) return "required_field";
        else if (this.$v.form.Email.$invalid) return "invalid_field";
        else return null;
    },
    PhoneError() {
        if (!this.$v.form.Phone.$anyError) return null;
        else if (!this.$v.form.Phone.required) return "required_field";
        else if (this.$v.form.Phone.$invalid) return "invalid_field";
        else return null;
    },
    BirthdayError() {
        if (!this.$v.form.Birthday.$anyError) return null;
        else if (!this.$v.form.Birthday.required) return "required_field";
        else if (this.$v.form.Birthday.$invalid) return "invalid_field";
        else return null;
    },
    SSNError() {
        if (!this.$v.form.SSN.$anyError) return null;
        else if (!this.$v.form.SSN.required) return "required_field";
        else if (this.$v.form.SSN.$invalid) return "invalid_field";
        else return null;
    },
    DriverLicenseStateError() {
        if (!this.$v.form.DriverLicenseState.$anyError) return null;
        else if (!this.$v.form.DriverLicenseState.required) return "required_field";
        else if (this.$v.form.DriverLicenseState.$invalid) return "invalid_field";
        else return null;
    },
    DriverLicenseError() {
        if (!this.$v.form.DriverLicense.$anyError) return null;
        else if (!this.$v.form.DriverLicense.required) return "required_field";
        else if (this.$v.form.DriverLicense.$invalid) return "invalid_field";
        else return null;
    },
}
