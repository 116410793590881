<template>
  <div class="textbox" :class="{ 'form-group--error': validator && validator.$error }"
       :style="{
                    '--bg': bg,
                    '--width': width,
                    '--height': height,
                    '--border': border,
                    '--border-color': borderColor,
                    '--border-radius': borderRadius,
                    '--padding': padding,
                    '--box-shadow': boxShadow,
                    '--font-size': fSize,
                    '--font-color': fColor,
                    '--placeholder-color': phColor,
                    '--placeholder-size': phFSize,
                }"
  >
    <label v-if="label"> {{ label }} </label>
    <input ref="textbox"
           :type="type"
           :placeholder="placeholder"
           :value="value" @input="updateText"
           :max="max"
           :min="min"
           :step="step"
           :disabled="disabled"
           :class="{'error':error}"
           @keyup="$emit('keyup', $event)"
           @keydown="$emit('keydown', $event)"
           v-uppercase="uppercase"
           v-lowercase="lowercase"
           v-if="type !== 'tel'">
    <the-mask v-else
              ref="textbox"
              :value="value"
              @input="updateTel"
              mask="(###) ###-####"
              placeholder="(786) 405-5698"
              type="tel"
              :masked="true"
              :disabled="disabled"
    />
    <span v-if="error && errorText" class="has-error animated headShake">{{ $t(error) }}</span>
    <div v-if="validator && validator.$error && validator.hasOwnProperty('required')">
      <span class="has-error animated headShake" v-if="!validator.required">{{ $t('required_field') }}</span>
    </div>
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask';

export default {
  name: "TextBox",
  components: {TheMask},
  props: {
    type: {type: String, default: 'text'},
    errorText: {type: Boolean, default: true},
    placeholder: {type: String, default: ''},
    change: {type: Function},
    min: [String, Number, Date],
    max: [String, Number, Date],
    width: {type: String, default: '100%'},
    value: [String, Number, Date],
    validator: null,
    label: [String, Number],
    error: {type: String},
    disabled: {type: Boolean, default: false},
    bg: {type: String, default: '#f3f4fb'},
    height: {type: String, default: '2.5rem'},
    border: {type: String, default: '1px'},
    borderRadius: {type: String, default: '.7rem'},
    borderColor: {type: String, default: '#E7E8F2'},
    padding: {type: String, default: '.3rem .5rem'},
    boxShadow: {type: String, default: '0 3px 12px'},
    fSize: {type: String, default: '1rem'},
    fColor: {type: String, default: '#032143'},
    phColor: {type: String, default: "#919198"},
    phFSize: {type: String, default: '1rem'},
    uppercase: {type: Boolean, default: false},
    lowercase: {type: Boolean, default: false},
    step: Number,
  },
  methods: {
    updateText() {
      this.$emit('input', this.$refs.textbox.value);
      this.$emit('change', this.$refs.textbox.value);
      if (this.change) this.change(this.$refs.textbox.value);
    },
    updateTel() {
      if (!this.$refs.textbox) return;
      this.$emit('input', this.$refs.textbox.$el.value);
      this.$emit('change', this.$refs.textbox.$el.value);
      if (this.change) this.change(this.$refs.textbox.$el.value);
    }
  },
  directives: {
    uppercase: {
      update: (el, {value}) => {
        if (value) {
          const start = el.selectionStart;
          const end = el.selectionEnd;
          el.value = el.value.toUpperCase();
          el.setSelectionRange(start, end);
        }
      }
    },

    lowercase: {
      update: (el, {value}) => {
        if (value) {
          const start = el.selectionStart;
          const end = el.selectionEnd;
          el.value = el.value.toLowerCase();
          el.setSelectionRange(start, end);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.textbox {
  position: relative;

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black2);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  input {
    background: var(--bg);
    border: var(--border) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--font-color);
    height: var(--height);
    font-size: var(--font-size);
    padding: var(--padding);
    font-weight: $font-bold;
    transition: .15s ease-in-out;
    width: var(--width);

    &.error {
      border-color: red;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &:focus {
      box-shadow: var(--box-shadow) rgba(color(bg-gray2), .3);
    }

    &[type="date"], &[type="datetime-local"] {

      &::-webkit-inner-spin-button {
        opacity: 0
      }
    }

    &[type="date"], &[type="datetime-local"] {

      &::-webkit-calendar-picker-indicator {
        color: var(--font-color);
        font-size: var(--font-size);
        opacity: 0.5
      }
    }

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
</style>

<i18n>
{
  "en": {
    "Invalid field": "Invalid field",
    "invalid_field": "Invalid field",
    "Required field": "Required field",
    "Invalid field, select at least one": "Invalid field, must select almost one",
    "Invalid field, must be greater": "Invalid field, must be greater",
    "required_field": "Required field",
    "out_of_schedule": "Out of schedule"
  },
  "es": {
    "Invalid field": "Campo inválido",
    "invalid_field": "Campo inválido",
    "Required field": "Campo obligatorio",
    "Invalid field, select at least one": "Campo no válido, debe seleccionar al menos una opción",
    "Invalid field, must be greater": "Campo no válido, debe ser mayor",
    "required_field": "Campo obligatorio",
    "out_of_schedule": "Fuera de horario"
  }
}
</i18n>
