export default [
    {"name": "Afghanistan", "abbreviation": "AF"},
    {"name": "land Islands", "abbreviation": "AX"},
    {"name": "Albania", "abbreviation": "AL"},
    {"name": "Algeria", "abbreviation": "DZ"},
    {"name": "American Samoa", "abbreviation": "AS"},
    {"name": "AndorrA", "abbreviation": "AD"},
    {"name": "Angola", "abbreviation": "AO"},
    {"name": "Anguilla", "abbreviation": "AI"},
    {"name": "Antarctica", "abbreviation": "AQ"},
    {"name": "Antigua and Barbuda", "abbreviation": "AG"},
    {"name": "Argentina", "abbreviation": "AR"},
    {"name": "Armenia", "abbreviation": "AM"},
    {"name": "Aruba", "abbreviation": "AW"},
    {"name": "Australia", "abbreviation": "AU"},
    {"name": "Austria", "abbreviation": "AT"},
    {"name": "Azerbaijan", "abbreviation": "AZ"},
    {"name": "Bahamas", "abbreviation": "BS"},
    {"name": "Bahrain", "abbreviation": "BH"},
    {"name": "Bangladesh", "abbreviation": "BD"},
    {"name": "Barbados", "abbreviation": "BB"},
    {"name": "Belarus", "abbreviation": "BY"},
    {"name": "Belgium", "abbreviation": "BE"},
    {"name": "Belize", "abbreviation": "BZ"},
    {"name": "Benin", "abbreviation": "BJ"},
    {"name": "Bermuda", "abbreviation": "BM"},
    {"name": "Bhutan", "abbreviation": "BT"},
    {"name": "Bolivia", "abbreviation": "BO"},
    {"name": "Bosnia and Herzegovina", "abbreviation": "BA"},
    {"name": "Botswana", "abbreviation": "BW"},
    {"name": "Bouvet Island", "abbreviation": "BV"},
    {"name": "Brazil", "abbreviation": "BR"},
    {"name": "British Indian Ocean Territory", "abbreviation": "IO"},
    {"name": "Brunei Darussalam", "abbreviation": "BN"},
    {"name": "Bulgaria", "abbreviation": "BG"},
    {"name": "Burkina Faso", "abbreviation": "BF"},
    {"name": "Burundi", "abbreviation": "BI"},
    {"name": "Cambodia", "abbreviation": "KH"},
    {"name": "Cameroon", "abbreviation": "CM"},
    {"name": "Canada", "abbreviation": "CA"},
    {"name": "Cape Verde", "abbreviation": "CV"},
    {"name": "Cayman Islands", "abbreviation": "KY"},
    {"name": "Central African Republic", "abbreviation": "CF"},
    {"name": "Chad", "abbreviation": "TD"},
    {"name": "Chile", "abbreviation": "CL"},
    {"name": "China", "abbreviation": "CN"},
    {"name": "Christmas Island", "abbreviation": "CX"},
    {"name": "Cocos (Keeling) Islands", "abbreviation": "CC"},
    {"name": "Colombia", "abbreviation": "CO"},
    {"name": "Comoros", "abbreviation": "KM"},
    {"name": "Congo", "abbreviation": "CG"},
    {"name": "Cook Islands", "abbreviation": "CK"},
    {"name": "Costa Rica", "abbreviation": "CR"},
    {"name": "Croatia", "abbreviation": "HR"},
    {"name": "Cuba", "abbreviation": "CU"},
    {"name": "Cyprus", "abbreviation": "CY"},
    {"name": "Czech Republic", "abbreviation": "CZ"},
    {"name": "Denmark", "abbreviation": "DK"},
    {"name": "Djibouti", "abbreviation": "DJ"},
    {"name": "Dominica", "abbreviation": "DM"},
    {"name": "Dominican Republic", "abbreviation": "DO"},
    {"name": "Ecuador", "abbreviation": "EC"},
    {"name": "Egypt", "abbreviation": "EG"},
    {"name": "El Salvador", "abbreviation": "SV"},
    {"name": "Equatorial Guinea", "abbreviation": "GQ"},
    {"name": "Eritrea", "abbreviation": "ER"},
    {"name": "Estonia", "abbreviation": "EE"},
    {"name": "Ethiopia", "abbreviation": "ET"},
    {"name": "Falkland Islands (Malvinas)", "abbreviation": "FK"},
    {"name": "Faroe Islands", "abbreviation": "FO"},
    {"name": "Fiji", "abbreviation": "FJ"},
    {"name": "Finland", "abbreviation": "FI"},
    {"name": "France", "abbreviation": "FR"},
    {"name": "Gabon", "abbreviation": "GA"},
    {"name": "Gambia", "abbreviation": "GM"},
    {"name": "Georgia", "abbreviation": "GE"},
    {"name": "Germany", "abbreviation": "DE"},
    {"name": "Ghana", "abbreviation": "GH"},
    {"name": "Gibraltar", "abbreviation": "GI"},
    {"name": "Greece", "abbreviation": "GR"},
    {"name": "Greenland", "abbreviation": "GL"},
    {"name": "Grenada", "abbreviation": "GD"},
    {"name": "Guadeloupe", "abbreviation": "GP"},
    {"name": "Guam", "abbreviation": "GU"},
    {"name": "Guatemala", "abbreviation": "GT"},
    {"name": "Guernsey", "abbreviation": "GG"},
    {"name": "Guinea", "abbreviation": "GN"},
    {"name": "Guinea-Bissau", "abbreviation": "GW"},
    {"name": "Guyana", "abbreviation": "GY"},
    {"name": "Haiti", "abbreviation": "HT"},
    {"name": "Honduras", "abbreviation": "HN"},
    {"name": "Hong Kong", "abbreviation": "HK"},
    {"name": "Hungary", "abbreviation": "HU"},
    {"name": "Iceland", "abbreviation": "IS"},
    {"name": "India", "abbreviation": "IN"},
    {"name": "Indonesia", "abbreviation": "ID"},
    {"name": "Iran, Islamic Republic Of", "abbreviation": "IR"},
    {"name": "Iraq", "abbreviation": "IQ"},
    {"name": "Ireland", "abbreviation": "IE"},
    {"name": "Isle of Man", "abbreviation": "IM"},
    {"name": "Israel", "abbreviation": "IL"},
    {"name": "Italy", "abbreviation": "IT"},
    {"name": "Jamaica", "abbreviation": "JM"},
    {"name": "Japan", "abbreviation": "JP"},
    {"name": "Jersey", "abbreviation": "JE"},
    {"name": "Jordan", "abbreviation": "JO"},
    {"name": "Kazakhstan", "abbreviation": "KZ"},
    {"name": "Kenya", "abbreviation": "KE"},
    {"name": "Kiribati", "abbreviation": "KI"},
    {"name": "Korea, Republic of", "abbreviation": "KR"},
    {"name": "Kuwait", "abbreviation": "KW"},
    {"name": "Kyrgyzstan", "abbreviation": "KG"},
    {"name": "Latvia", "abbreviation": "LV"},
    {"name": "Lebanon", "abbreviation": "LB"},
    {"name": "Lesotho", "abbreviation": "LS"},
    {"name": "Liberia", "abbreviation": "LR"},
    {"name": "Libyan Arab Jamahiriya", "abbreviation": "LY"},
    {"name": "Liechtenstein", "abbreviation": "LI"},
    {"name": "Lithuania", "abbreviation": "LT"},
    {"name": "Luxembourg", "abbreviation": "LU"},
    {"name": "Macao", "abbreviation": "MO"},
    {"name": "Madagascar", "abbreviation": "MG"},
    {"name": "Malawi", "abbreviation": "MW"},
    {"name": "Malaysia", "abbreviation": "MY"},
    {"name": "Maldives", "abbreviation": "MV"},
    {"name": "Mali", "abbreviation": "ML"},
    {"name": "Malta", "abbreviation": "MT"},
    {"name": "Marshall Islands", "abbreviation": "MH"},
    {"name": "Martinique", "abbreviation": "MQ"},
    {"name": "Mauritania", "abbreviation": "MR"},
    {"name": "Mauritius", "abbreviation": "MU"},
    {"name": "Mayotte", "abbreviation": "YT"},
    {"name": "Mexico", "abbreviation": "MX"},
    {"name": "Moldova, Republic of", "abbreviation": "MD"},
    {"name": "Monaco", "abbreviation": "MC"},
    {"name": "Mongolia", "abbreviation": "MN"},
    {"name": "Montenegro", "abbreviation": "ME"},
    {"name": "Montserrat", "abbreviation": "MS"},
    {"name": "Morocco", "abbreviation": "MA"},
    {"name": "Mozambique", "abbreviation": "MZ"},
    {"name": "Myanmar", "abbreviation": "MM"},
    {"name": "Namibia", "abbreviation": "NA"},
    {"name": "Nauru", "abbreviation": "NR"},
    {"name": "Nepal", "abbreviation": "NP"},
    {"name": "Netherlands", "abbreviation": "NL"},
    {"name": "Netherlands Antilles", "abbreviation": "AN"},
    {"name": "New Caledonia", "abbreviation": "NC"},
    {"name": "New Zealand", "abbreviation": "NZ"},
    {"name": "Nicaragua", "abbreviation": "NI"},
    {"name": "Niger", "abbreviation": "NE"},
    {"name": "Nigeria", "abbreviation": "NG"},
    {"name": "Niue", "abbreviation": "NU"},
    {"name": "Norfolk Island", "abbreviation": "NF"},
    {"name": "Northern Mariana Islands", "abbreviation": "MP"},
    {"name": "Norway", "abbreviation": "NO"},
    {"name": "Oman", "abbreviation": "OM"},
    {"name": "Pakistan", "abbreviation": "PK"},
    {"name": "Palau", "abbreviation": "PW"},
    {"name": "Palestinian Territory, Occupied", "abbreviation": "PS"},
    {"name": "Panama", "abbreviation": "PA"},
    {"name": "Papua New Guinea", "abbreviation": "PG"},
    {"name": "Paraguay", "abbreviation": "PY"},
    {"name": "Peru", "abbreviation": "PE"},
    {"name": "Philippines", "abbreviation": "PH"},
    {"name": "Pitcairn", "abbreviation": "PN"},
    {"name": "Poland", "abbreviation": "PL"},
    {"name": "Portugal", "abbreviation": "PT"},
    {"name": "Puerto Rico", "abbreviation": "PR"},
    {"name": "Qatar", "abbreviation": "QA"},
    {"name": "Reunion", "abbreviation": "RE"},
    {"name": "Romania", "abbreviation": "RO"},
    {"name": "Russian Federation", "abbreviation": "RU"},
    {"name": "RWANDA", "abbreviation": "RW"},
    {"name": "Saint Helena", "abbreviation": "SH"},
    {"name": "Saint Kitts and Nevis", "abbreviation": "KN"},
    {"name": "Saint Lucia", "abbreviation": "LC"},
    {"name": "Saint Pierre and Miquelon", "abbreviation": "PM"},
    {"name": "Samoa", "abbreviation": "WS"},
    {"name": "San Marino", "abbreviation": "SM"},
    {"name": "Sao Tome and Principe", "abbreviation": "ST"},
    {"name": "Saudi Arabia", "abbreviation": "SA"},
    {"name": "Senegal", "abbreviation": "SN"},
    {"name": "Serbia", "abbreviation": "RS"},
    {"name": "Seychelles", "abbreviation": "SC"},
    {"name": "Sierra Leone", "abbreviation": "SL"},
    {"name": "Singapore", "abbreviation": "SG"},
    {"name": "Slovakia", "abbreviation": "SK"},
    {"name": "Slovenia", "abbreviation": "SI"},
    {"name": "Solomon Islands", "abbreviation": "SB"},
    {"name": "Somalia", "abbreviation": "SO"},
    {"name": "South Africa", "abbreviation": "ZA"},
    {"name": "Spain", "abbreviation": "ES"},
    {"name": "Sri Lanka", "abbreviation": "LK"},
    {"name": "Sudan", "abbreviation": "SD"},
    {"name": "Suriname", "abbreviation": "SR"},
    {"name": "Svalbard and Jan Mayen", "abbreviation": "SJ"},
    {"name": "Swaziland", "abbreviation": "SZ"},
    {"name": "Sweden", "abbreviation": "SE"},
    {"name": "Switzerland", "abbreviation": "CH"},
    {"name": "Syrian Arab Republic", "abbreviation": "SY"},
    {"name": "Taiwan, Province of China", "abbreviation": "TW"},
    {"name": "Tajikistan", "abbreviation": "TJ"},
    {"name": "Tanzania, United Republic of", "abbreviation": "TZ"},
    {"name": "Thailand", "abbreviation": "TH"},
    {"name": "Timor-Leste", "abbreviation": "TL"},
    {"name": "Togo", "abbreviation": "TG"},
    {"name": "Tokelau", "abbreviation": "TK"},
    {"name": "Tonga", "abbreviation": "TO"},
    {"name": "Trinidad and Tobago", "abbreviation": "TT"},
    {"name": "Tunisia", "abbreviation": "TN"},
    {"name": "Turkey", "abbreviation": "TR"},
    {"name": "Turkmenistan", "abbreviation": "TM"},
    {"name": "Turks and Caicos Islands", "abbreviation": "TC"},
    {"name": "Tuvalu", "abbreviation": "TV"},
    {"name": "Uganda", "abbreviation": "UG"},
    {"name": "Ukraine", "abbreviation": "UA"},
    {"name": "United Arab Emirates", "abbreviation": "AE"},
    {"name": "United Kingdom", "abbreviation": "GB"},
    {"name": "United States", "abbreviation": "US"},
    {"name": "Uruguay", "abbreviation": "UY"},
    {"name": "Uzbekistan", "abbreviation": "UZ"},
    {"name": "Vanuatu", "abbreviation": "VU"},
    {"name": "Venezuela", "abbreviation": "VE"},
    {"name": "Viet Nam", "abbreviation": "VN"},
    {"name": "Virgin Islands, British", "abbreviation": "VG"},
    {"name": "Virgin Islands, U.S.", "abbreviation": "VI"},
    {"name": "Wallis and Futuna", "abbreviation": "WF"},
    {"name": "Western Sahara", "abbreviation": "EH"},
    {"name": "Yemen", "abbreviation": "YE"},
    {"name": "Zambia", "abbreviation": "ZM"},
    {"name": "Zimbabwe", "abbreviation": "ZW"}
];