var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"combobox",staticClass:"combobox",class:{ 'form-group--error': _vm.validator && _vm.validator.$error }},[(_vm.label)?_c('label',{staticClass:"combobox__label"},[_vm._v(" "+_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"combobox__select",style:({
                '--min-width'     : _vm.minWidth,
                '--max-height'    : _vm.maxHeight,
                '--bg-selected'   : _vm.bgSelected,
                '--bg-options'    : _vm.bgOptions,
                '--color-selected': _vm.colorSelected,
                '--color-options' : _vm.colorOptions,
                '--border'        : _vm.border,
                '--height'        : _vm.height,
                '--border-color'  : _vm.borderColor
              })},[_c('div',{staticClass:"combobox__selected",class:{'active': _vm.deployed},on:{"click":_vm.deploy}},[(_vm.option !== null && _vm.option !== undefined)?_c('span',[_vm._v(_vm._s(_vm.valueText ? _vm.option[_vm.valueText] : _vm.option))]):_c('span',[_vm._v(_vm._s(_vm.text))]),(_vm.isLoading)?_c('span',[_c('Spinner',{attrs:{"color":"#221ecd","size":"1.4rem"}})],1):_c('i',{staticClass:"ez-icon-caret-down"})]),(_vm.deployed)?_c('div',{staticClass:"combobox__options active"},[(_vm.showSearch)?_c('div',{staticClass:"combobox__filter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],attrs:{"type":"search","placeholder":_vm.$t('search')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]):_vm._e(),(_vm.optionsFiltered && _vm.optionsFiltered.length > 0)?_c('ul',{staticClass:"combobox__list ez-slim-scroll"},_vm._l((_vm.optionsFiltered),function(option){return _c('li',{key:_vm.valueKey ? option[_vm.valueKey] : option,attrs:{"value":_vm.valueKey ? option[_vm.valueKey] : option},on:{"click":function($event){return _vm.updateSelect(option)}}},[_vm._v(_vm._s(_vm.valueText ? option[_vm.valueText] : option)+" ")])}),0):_c('div',{staticClass:"no-element"},[_vm._v(_vm._s(_vm.$t('no_element')))])]):_vm._e()]),(_vm.error)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),(_vm.validator && _vm.validator.$error)?_c('div',[(!_vm.validator.required)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t('field required')))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }