import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class CompanyService extends IService {
    constructor(http) {
        super(http);
    }

    async info() {
        try {
            const response = await this.http.get(`${server_base}/api/company/public`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getInventorySettings() {
        try {
            const response = await this.http.get(`${server_base}/api/company/inventory_settings/external`);
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default CompanyService;