import Validations from './validation';
import Errors from './Errors';
import utils from "@/helper/utils";
import BusinessCardService from "@/services/business-card.service";
import config from "@/config";
import urlParams from "@/helper/urlParams";

const server_base = config[config.stage].ws_server;

const service = new BusinessCardService();
export default {
  name: 'business-card',
  components: {},
  props: [],
  data() {
    return {
      card: {},
      agent: '',
      flip: false,
      sentMessage: null,
      shareTab: 'qr',
      hasSocialNetwork: false,
      PhoneTo: '',
      EmailTo: '',
      isLoading: false,
      vCard: null,
    }
  },
  validations: Validations,
  computed: {
    ...Errors,

    qrImage() {
      return `data:image/png;base64,${this.card.QRCode}`
    },

    userPhoto() {
      return `${server_base}/api/files/avatar/${this.agent}`;

    },

    saveVCard() {
      if (this.vCard) return `data:text/x-vcard;charset=utf-8;base64,${this.vCard}`
    }


  },
  async mounted() {

    if (urlParams.has('agent')) {
      this.agent = urlParams.get('agent');
      await this.getCard()
      await this.getVCardToPhone();
    }
  },
  methods: {
    async getCard() {
      this.isLoading = true;

      const res = await service.getCard(this.agent);
      if (res && !res.error) {
        this.isLoading = false;

        this.card = res.data;
        this.handleSocialsNetwork(res.data.SocialsNetwork)
      } else {
        this.isLoading = false;
      }
    },

    async getVCardToPhone() {
      try {
        const res = await service.getVCardToPhone(this.card._id)
        if (res && !res.error) {
          this.vCard = res.data;
        }
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    },

    async sendCardByEmail() {
      try {
        const result = await this.$v.EmailTo;
        if (result.$invalid) return;


        const email = {
          agentName: `${this.card.FirstName} ${this.card.LastName}`,
          to: this.EmailTo,
          link: `https://ez-l.cc/${this.card.ShortUrlCode}`
        }

        const res = await service.sendEmail(email)
        if (res && !res.error) {
          this.sentMessage = 'Email Sent!'
          this.EmailTo = '';
          setTimeout(() => {
            this.sentMessage = null
          }, 3000)
        }
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    },

    async sendCarBySms() {
      try {
        const result = await this.$v.PhoneTo;
        if (result.$invalid) return;

        const data = {
          agentName: `${this.card.FirstName} ${this.card.LastName}`,
          to: this.PhoneTo,
          link: `https://ez-l.cc/${this.card.ShortUrlCode}`
        }

        const res = await service.sendSMS(data)
        if (res && !res.error) {
          this.sentMessage = 'SMS Sent!'
          this.PhoneTo = '';
          setTimeout(() => {
            this.sentMessage = null
          }, 3000)
        }
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    },

    contestImageURL(contest) {
      return this.$s3_url() + 'contest/' + contest;
    },


    role(role) {
      const ROLE = {
        'admin': 'Administrator',
        'manager': 'Sales Representative',
        'bdc': 'BDC Representative',
        'seller': 'Seller Representative',
        'reception': 'Reception Representative',
      }

      return ROLE[role] || 'Seller Agent'
    },

    frontCard() {
      this.flip = false;
      this.PhoneTo = '';
      this.EmailTo = '';
    },

    backCard() {
      this.flip = true;
    },

    handleShareTab(tab) {
      const TABS = {
        'qr': this.shareTab = tab,
        'email': this.shareTab = tab,
        'text': this.shareTab = tab,
      }

      return TABS[tab];
    },

    handleSocialsNetwork(networks) {
      if (!networks) return;

      const array = Object.keys(networks)
      const newArr = array.filter(el => {
        return networks[el].enabled;
      })

      if (newArr.length) this.hasSocialNetwork = true;
    }

  },
  filters: {
    formatUSNumber(value) {
      if (utils.isUsPhone(value)) {
        return utils.formatUSNumber(value);
      }
      return value;
    },
  }
}


