import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue'
import store from './store'
import config from './config';
import {i18n} from '@/helper/i18n';

import LocalStore from './helper/LocalStore';
import './helper/import.ui.components';

import CompanyService from './services/company.service';
const companies = new CompanyService();

const env = config.stage;

Vue.config.productionTip = env === 'production' || env === 'staging';

Vue.use(Vuelidate);

if (!LocalStore.hasSID())
    LocalStore.setSID();

Vue.prototype.$ws_server = function () {
    return config[env].ws_server;
};

Vue.prototype.$cdn_server = function () {
    return config[env].cdn_server;
};

Vue.prototype.$getCompany = function () {
    return LocalStore.getCompany();
};

Vue.prototype.$s3_url = function () {
    return config[env].s3_url;
};

const container_div = document.getElementById('ez_leads_forms');
if (container_div !== null) {

    companies.info()
        .then(res => {
            if (res && !res.error) {
                LocalStore.setCompany(res.data);

                new Vue({
                    store,
                    i18n,
                    render: h => h(App)
                }).$mount('#ez_leads_forms');


            } else {
                console.error('Licensing Error');
            }
        })
        .catch(err => {
            console.error(err);
        });
}
