<template>
  <section>
    <div class="selectvin" v-if="type === 'drop'">
      <div class="selectvin-input">
        <label v-if="label"> {{ label }} </label>
        <div ref="search_box" :style="{'--border-radius': borderRadius}">
          <i class="ez-icon-search" :style="{'--bg': bg}"></i>
          <input type="search" ref="search" :value="value" :disabled="disabled" :style="{
                    '--bg': bg,
                    '--width': width,
                    '--height': height,
                    '--border': border,
                    '--border-color': borderColor,
                    '--border-radius': borderRadius,
                    '--padding': padding,
                    '--box-shadow': boxShadow,
                    '--font-size': fSize,
                    '--font-color': fColor,
                    '--placeholder-color': phColor,
                    '--placeholder-size': phFSize,
                }"
                 :placeholder="$t('search_placeholder')" @input="updateText">
          <span v-if="error" class="has-error animated headShake">{{ error }}</span>
        </div>
      </div>
      <div class="selectvin-results ez-slim-scroll" v-if="visible && cars.length" :style="{
          '--border-radius': borderRadius,
          '--box-shadow': boxShadow,
        }">
        <ul>
          <li v-for="car in cars" :key="car.VIN" @click="setVin(car)">
            <figure>
              <img :src="image(car)">
            </figure>
            <div class="info">
              <div class="car-title">{{ car.Year }} {{ car.Make }} {{ car.Model }}</div>
              <div class="car-field">
                <span>Stock</span>
                <span>{{ car.Stock }}</span>
              </div>
              <div class="car-field">
                <span>VIN</span>
                <span>{{ car.VIN }}</span>
              </div>
              <div class="car-field">
                <span>{{ $t('mileage') }}</span>
                <span>{{ car.Mileage }}</span>
              </div>
              <div class="car-field">
                <span>{{ $t('price') }}</span>
                <span>${{ car.Price.Value }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="selectvin car-list" v-if="type === 'list'">
      <div class="selectvin-input">
        <div ref="search_box">
          <i class="ez-icon-search" :style="{'--bg': bg}"></i>
          <input type="search" ref="search" :value="value" :style="{'--bg': bg}"
                 :placeholder="$t('search_placeholder')" @input="selectText">
          <span v-if="error" class="has-error animated headShake">{{ error }}</span>
        </div>
      </div>
      <ul class="ez-slim-scroll" v-if="cars.length">
        <li :class="{'selected': selectedCar && selectedCar.VIN === car.VIN}" v-for="car in cars" :key="car.VIN"
            @click="selectCar(car)">
          <figure>
            <img :src="image(car)">
          </figure>
          <div class="info">
            <div class="car-title">{{ car.Year }} {{ car.Make }} {{ car.Model }}</div>
            <div class="car-field">
              <span>Stock</span>
              <span>{{ car.Stock }}</span>
            </div>
            <div class="car-field">
              <span>VIN</span>
              <span>{{ car.VIN }}</span>
            </div>
            <div class="car-field">
              <span>{{ $t('mileage') }}</span>
              <span>{{ car.Mileage }}</span>
            </div>
            <div class="car-field">
              <span>{{ $t('price') }}</span>
              <span>${{ car.Price }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="car-list__empty" v-else>
        <i class="ez-icon-car"></i>
        <span>{{ $t('no_result') }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import InventoryService from '@/services/inventory.service';
import CompanyService from '@/services/company.service';

const companyService = new CompanyService();

const service = new InventoryService();

export default {
  name: "SelectVin",

  props: {
    value: {type: String},
    label: {type: String},
    error: {type: String},
    bg: {type: String, default: 'white'},
    height: {type: String, default: '35px'},
    border: {type: String, default: '1px'},
    borderRadius: {type: String, default: '3px'},
    borderColor: {type: String, default: '#E7E8F2'},
    padding: {type: String, default: '.3rem .5rem'},
    boxShadow: {type: String, default: '0 3px 12px'},
    fSize: {type: String, default: '.8rem'},
    fColor: {type: String, default: '#495057'},
    phColor: {type: String, default: "#8798AD"},
    phFSize: {type: String, default: '.8rem'},
    width: {type: String, default: '100%'},
    type: {type: String, default: 'drop'},
    disabled: {type: Boolean, default: false},
  },

  data() {
    return {
      visible: false,
      cars: [],
      car: null,
      selectedCar: null
    }
  },

  async mounted() {
    const vinRgExp = /^[A-HJ-NPR-Z\d]{8}[\dX][A-HJ-NPR-Z\d]{2}\d{6}$/;
    if (vinRgExp.test(this.value)) {
      await this.getCar();
      this.$emit('change', this.car);
    }

    if (this.car) {
      this.selectedCar = this.car;
    }

    if (this.type === 'list') {
      this.listInventory()
    }
  },

  methods: {

    documentClick(e) {
      const target = e.target;
      const el = this.$refs.search_box;
      this.visible = !((el !== target) && el && !el.contains(target));
    },

    updateText() {
      this.$emit('input', this.$refs.search.value);

      if (this.$refs.search.value)
        this.listInventory();
      else
        this.cars = [];
    },

    async listInventory() {
      const limit = 10;
      const skip = 0;

      const res = await service.getCarList(limit, skip, {searchTerm: this.$refs.search.value});
      if (res && !res.error) {
        this.cars = res.data.cars;
        this.visible = true;
      }
    },

    async getCar() {
      const res = await service.getByVin(this.$refs.search.value);
      if (res && !res.error)
        this.car = res.data;
    },

    async setVin(car) {
      this.$emit('input', car.VIN);
      this.$emit('change', car);
      this.$emit('carSelected', car);
      this.$emit('vinSelected', car.VIN);
      this.cars = [];
    },

    async selectCar(car) {
      this.$emit('input', car.VIN);
      this.$emit('change', car);
      this.$emit('carSelected', car);
      this.$emit('vinSelected', car.VIN);
      this.selectedCar = car;
    },

    selectText() {
      this.$emit('input', this.$refs.search.value);

      this.listInventory();
    },

    image(car) {
      if (!car.Images) return;

      const base_url = `${this.$cdn_server()}/api/files/`;
      const no_image = 'https://static.ezleadscdn.com/no-vehicle-image_sd.png';
      const selectedImage = car.SelectedImage;

      if (selectedImage)
        return base_url + selectedImage + '?resolution=360&frame=off';

      const firstImage = car.Images.length > 0 ? car.Images[0].File + '?resolution=360&frame=off' : null;

      if (firstImage)
        return base_url + firstImage;

      const firstEmpty = this.EmptyPhotos.find(p => p.Selected === true);
      if (firstEmpty)
        return base_url + firstEmpty.File;

      if (this.$getCompany().CompanySettings
          && this.$getCompany().CompanySettings.Inventory
          && this.$getCompany().CompanySettings.Inventory.DefaultCarImage) {
        return `${this.$ws_server()}/api/files/${this.$getCompany().CompanySettings.Inventory.DefaultCarImage}`
      }

      return no_image;
    }
  },

  async beforeCreate() {
    const res = await companyService.getInventorySettings();
    if (res && !res.error) {
      this.EmptyPhotos = res.data.EmptyPhotos;
    }
  },

  async created() {
    document.addEventListener('click', this.documentClick);
  },

  destroyed() {
    document.removeEventListener('click', this.documentClick);
  }
}
</script>

<style scoped lang="scss">

.selectvin {
  position: relative;

  &-input {
    position: relative;

    label {
      display: block;
      margin-bottom: 5px;
      color: color(bg-black3);
      font-size: .7rem;
      font-weight: $font-medium;
    }

    div {
      background: color(bg-white);
      height: 2.625rem;
      width: 100%;
      border: 1px solid #E7E8F2;
      display: flex;
      border-radius: 3px;
      overflow: hidden;
      transition: .15s ease-in-out;

      i {
        align-items: center;
        background: color(bg-white);
        color: #8798AD;
        display: flex;
        font-size: 1.2rem;
        justify-content: center;
        width: 2.7rem;
      }

      input {
        background: color(bg-white);
        border: none;
        color: #495057;
        flex: 1;
        font-size: 85%;
        height: 100%;
        padding-right: .5em;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #8798AD;
          font-size: .8rem;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #8798AD;
          font-size: .8rem;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: #8798AD;
          font-size: .8rem;
        }
      }

      &:focus-within {
        box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
      }

      .has-error {
        position: absolute;
        bottom: -14px;
        font-size: .7rem;
        right: 5px;
        color: color(bg-red);
        font-weight: $font-medium;
      }

      .bounce {
        -webkit-animation-name: bounce;
        animation-name: bounce;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
      }
    }
  }

  &-results {
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow) rgba(color(bg-gray2), .3);
    max-height: 259px;
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    ul {
      list-style: none;

      li {
        display: flex;
        padding: 10px;
        background-color: color(bg-white);
        border: 1px solid color(border);
        transition: ease-in-out .1s;
        cursor: pointer;

        figure {
          width: 100px;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        .info {
          color: color(bg-black2);
          display: grid;
          grid-template-columns: 1fr 1fr;

          .car-title {
            font-weight: $font-medium;
            grid-column: span 2;
          }

          .car-field {
            display: grid;
            grid-template-columns: 50px auto;

            span {
              line-height: 21px;
              font-size: .7rem;

              &:first-child {
                color: color(bg-black1);
                font-weight: $font-medium;
              }
            }
          }
        }

        &:hover {
          background-color: color(bg-gray3);
        }

        &.selected {
          border: 1px solid color(bg-primary);
          background-color: color(bg-primary-light);
        }
      }
    }
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }
}

.car-list {
  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    margin-top: 10px;

    i {
      position: relative;
      font-size: 5rem;

      &:after {
        content: '';
        height: 100%;
        background-color: color(bg-red);
        border-radius: 6px;
        width: 6px;
        position: absolute;
        box-shadow: 3px 0 0 1px color(bg-fill);
        left: calc(50% - 6px);
        bottom: 0px;
        transform: rotate(45deg);
      }
    }

    span, i {
      color: color(bg-black3);
    }
  }

  ul {
    margin-top: 10px;
    list-style: none;
    height: 400px;
    overflow: auto;

    li {
      border-radius: 6px;
      display: flex;
      padding: 10px;
      background-color: color(bg-white);
      margin-top: 10px;
      border: 1px solid color(border);
      margin-right: 10px;
      transition: ease-in-out .1s;
      cursor: pointer;

      figure {
        width: 100px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      .info {
        color: color(bg-black2);
        display: grid;
        grid-template-columns: 1fr 1fr;

        .car-title {
          font-weight: $font-medium;
          grid-column: span 2;
        }

        .car-field {
          display: grid;
          grid-template-columns: 50px auto;

          span {
            line-height: 21px;
            font-size: .7rem;

            &:first-child {
              color: color(bg-black1);
              font-weight: $font-medium;
            }
          }
        }
      }

      &:hover {
        background-color: color(bg-gray3);
      }

      &.selected {
        border: 1px solid color(bg-primary);
        background-color: color(bg-primary-light);
      }
    }
  }
}
</style>

<i18n scope>
{
"en": {
"price": "Price",
"mileage": "Mileage",
"search_placeholder": "Search by Make, Model, Stock, Vin",
"no_result": "No cars found."
},
"es": {
"price": "Precio",
"search_placeholder": "Búsqueda por Marca, Model, Stock, Vin",
"no_result": "No se encontraron resultados.",
"mileage": "Millas"
}
}
</i18n>