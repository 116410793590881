<template>
  <div>
    <button type="button" @click="$emit('click')"
            :disabled="disabled || isLoading"
            :style="{
                    '--fsize': fsize,
                    '--width': width,
                    '--height': height,
                    '--bg': bg,
                    '--bg_hover': bg_hover,
                    '--border': border,
                    '--bradius': bradius,
                    '--color': color,
                    '--justify': justify,
                    '--padding': padding,
                    '--icon_margin': iconMargin,
                    '--fweight': fweight
                  }"
            :class="{
                    'btn-primary': type === 'primary',
                    'btn-secondary': type === 'secondary',
                    'btn-danger': type === 'danger',
                    'btn-delete': type === 'delete',
                    'btn-custom': type === 'custom',
                    'btn-edit': type === 'edit',
                    'btn-cancel': type === 'cancel',
                    'btn-small': size === 's',
                    'btn-middle': size === 'm',
                    'btn-large': size === 'l',
                    'btn-extra': size === 'xl'
                  }"
    >
      <i v-if="iconType === 'fa' && iconLeft && iconLeft.length > 0">
        <font-awesome-icon :icon="[iconType, iconLeft]"/>
      </i>
      <i v-if="iconType === 'fal' && iconLeft && iconLeft.length > 0">
        <font-awesome-icon :icon="[iconType, iconLeft]"/>
      </i>
      <i v-else-if="iconType === 'url' && iconLeft && iconLeft.length > 0">
        <img :src="iconLeft" alt=""/>
      </i>
      <i v-if="iconType === 'ez' && iconLeft && iconLeft.length > 0" :class="iconLeft"/>
      {{ text }}
      <i v-if="iconType === 'fa' && iconRight && iconRight.length > 0">
        <fa :icon="iconRight"/>
      </i>
      <i v-else-if="iconType === 'url' && iconRight && iconRight.length > 0">
        <img :src="iconRight" alt=""/>
      </i>
      <i v-else-if="iconType === 'ez' && iconRight && iconRight.length > 0" :class="iconRight"/>
      <div class="spinner" v-if="isLoading">
        <Spinner size="20px"/>
      </div>
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {type: String, default: ''},
    iconType: {type: String, enum: ['url', 'fa', 'fal', 'ez'], default: 'fa'},
    iconRight: String,
    iconLeft: String,
    iconMargin: {type: String},
    type: {
      type: String,
      enum: ['primary', 'secondary', 'cancel', 'danger', 'delete', 'edit'],
      default: 'primary'
    },
    size: {
      type: String,
      enum: ['s', 'm', 'l', 'xl'],
      default: 's'
    },
    disabled: {type: Boolean, default: false},
    fsize: {type: String},
    fweight: {type: String},
    width: {type: String},
    height: {type: String},
    border: {type: String},
    bradius: {type: String},
    bg: {type: String},
    bg_hover: {type: String},
    color: {type: String},
    justify: {type: String},
    padding: {type: String},
    click: {type: Function},
    isLoading: {type: Boolean, default: false}
  }
}
</script>

<style scoped lang="scss">
button {
  --fsize: 14px;
  --width: 50px;
  --height: 40px;
  --bg: #{color(bg-primary)};
  --border: transparent;
  --bradius: 3px;
  --bg_hover: #{color(primary-hover)};
  --color: white;
  --justify: center;
  --padding: 6px 12px;
  --icon_margin: 5px;
  --fweight: $font-light;

  padding: var(--padding);
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 0;
  font-size: var(--fsize);
  outline: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-weight: var(--fweight);
  min-width: var(--width);
  -ms-user-select: none;
  user-select: none;
  min-height: var(--height);
  transition: ease-in-out .2s;
  display: flex;
  align-items: center;
  justify-content: var(--justify);
  border: 1px solid transparent;
  border-radius: var(--bradius);
  gap: var(--icon_margin);

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.btn {
    &-primary {
      box-shadow: inset 0 0 .8rem 2px rgba(color(bg-blue-dark), .3);
      background: var(--bg);
      color: var(--color);

      &:hover {
        background: color(primary-hover-dark);
        color: white;
      }

      &:active {
        background: lighten(color(bg-primary), 5%);
      }

      i {
        color: white;
      }

    }

    &-danger {
      background: rgba(color(bg-orange), .6);
      color: white;

      &:hover {
        background: rgba(color(bg-orange), 1);
      }

      &:active {
        background: lighten(color(bg-red), 5%);
      }

      i {
        color: white;
      }

    }

    &-delete {
      background: white;
      border: 1px solid color(border);
      color: color(bg-red);
      font-weight: $font-medium;

      &:hover {
        background: color(bg-red);
        color: white;

        i {
          color: white;
        }
      }

      &:active {
        background: lighten(color(bg-red), 5%);
      }

      i {
        color: color(bg-red);
      }

    }

    &-secondary {
      background: color(bg-primary-light);
      color: color(bg-primary);

      &:hover {
        background: color(bg-primary);
        color: white;

        i {
          color: white;
        }
      }

      &:active {
        background: lighten(color(bg-gray2), 5%);
      }
    }

    &-cancel {
      background: none;
      border: 1px solid color(bg-primary);
      color: color(bg-black2);

      &:hover {
        background: color(bg-primary-light);
        color: color(bg-black1);
      }
    }

    &-custom {
      background: var(--bg);
      border: 1px solid var(--border);
      color: var(--color);

      &:hover {
        background: var(--bg_hover);
      }
    }

    &-edit {
      background: color(bg-primary-light);
      color: color(bg-primary);

      &:hover {
        background: color(primary-hover-dark);
        color: white;
      }

      &:active {
        background: lighten(color(bg-primary), 5%);
      }

      i {
        color: white;
      }

    }

    &-small {
      height: 32px;
    }

    &-middle {
      height: 40px;
    }

    &-large {
      height: 48px;
    }

    &-extra {
      height: 56px;
    }
  }

  .spinner {
    margin-left: 12px;
  }
}
</style>
