import {required, email} from 'vuelidate/lib/validators';


const // Custom validators
    isMobilePhone = value => (/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(value));

export default {
    EmailTo: {
        email,
        required
    },

    PhoneTo: {
        isMobilePhone,
        required
    },
}
