var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textbox",class:{ 'form-group--error': _vm.validator && _vm.validator.$error },style:({
                  '--bg': _vm.bg,
                  '--width': _vm.width,
                  '--height': _vm.height,
                  '--border': _vm.border,
                  '--border-color': _vm.borderColor,
                  '--border-radius': _vm.borderRadius,
                  '--padding': _vm.padding,
                  '--box-shadow': _vm.boxShadow,
                  '--font-size': _vm.fSize,
                  '--font-color': _vm.fColor,
                  '--placeholder-color': _vm.phColor,
                  '--placeholder-size': _vm.phFSize,
              })},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.type !== 'tel')?_c('input',{directives:[{name:"uppercase",rawName:"v-uppercase",value:(_vm.uppercase),expression:"uppercase"},{name:"lowercase",rawName:"v-lowercase",value:(_vm.lowercase),expression:"lowercase"}],ref:"textbox",class:{'error':_vm.error},attrs:{"type":_vm.type,"placeholder":_vm.placeholder,"max":_vm.max,"min":_vm.min,"step":_vm.step,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.updateText,"keyup":function($event){return _vm.$emit('keyup', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)}}}):_c('the-mask',{ref:"textbox",attrs:{"value":_vm.value,"mask":"(###) ###-####","placeholder":"(786) 405-5698","type":"tel","masked":true,"disabled":_vm.disabled},on:{"input":_vm.updateTel}}),(_vm.error && _vm.errorText)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t(_vm.error)))]):_vm._e(),(_vm.validator && _vm.validator.$error && _vm.validator.hasOwnProperty('required'))?_c('div',[(!_vm.validator.required)?_c('span',{staticClass:"has-error animated headShake"},[_vm._v(_vm._s(_vm.$t('required_field')))]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }