import { render, staticRenderFns } from "./Contact.html?vue&type=template&id=44f77481&scoped=true&external"
import script from "./Contact.js?vue&type=script&lang=js&external"
export * from "./Contact.js?vue&type=script&lang=js&external"
import style0 from "./Contact.scss?vue&type=style&index=0&id=44f77481&prod&shadow=true&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f77481",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FContact%2Findex.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports