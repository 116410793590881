import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class BusinessCardService extends IService {
    constructor(http) {
        super(http);
    }


    async getCard(agent) {
        try {
            const response = await this.http.get(`${server_base}/api/business-card/agent-card`, {agent});
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async sendEmail(email) {
        try {
            const response = await this.http.post(`${server_base}/api/business-card/send-email`, email);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async sendSMS(data) {
        try {
            const response = await this.http.post(`${server_base}/api/business-card/send-sms`, data);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async getVCardToPhone(card) {
        try {
            const response = await this.http.get(`${server_base}/api/business-card/vcard`, {card});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default BusinessCardService;
