import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class FinancingService extends IService {
    constructor(http) {
        super(http);
    }

    async create(form) {
        try {
            const response = await this.http.post(`${server_base}/api/financing/external`, {form});
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async get(id) {
        try {
            const response = await this.http.get(`${server_base}/api/financing/${id}/external`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async acceptTerms(form) {
        try {
            const response = await this.http.put(`${server_base}/api/financing/accept-terms`, {form});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default FinancingService;