<template>
  <div class="main">
    <Financing v-if="formType === 'financing'"/>
    <Contact v-else-if="formType === 'contact'"/>
    <Affiliate v-else-if="formType === 'affiliate'"/>
    <Referral v-else-if="formType === 'referral'"/>
    <Teams v-else-if="formType === 'teams'"/>
    <BusinessCard v-else-if="formType === 'business_card'"/>
  </div>
</template>

<script>
import urlParams from "@/helper/urlParams";

import Affiliate from './Affiliate/index';
import Referral from './Referral/index';
import Financing from './Financing/index';
import Contact from './Contact/index';
import Teams from './Teams/index';
import BusinessCard from './BusinessCard/index';

export default {
  name: "FormsIndex",

  components: {
    Financing,
    Contact,
    Affiliate,
    Referral,
    BusinessCard,
    Teams
  },

  data() {
    return {
      formType: ''
    }
  },

  mounted() {
    const hasFormType = urlParams.has('formType');

    if (hasFormType) {
      this.formType = urlParams.get('formType').toLowerCase();
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
}
</style>
