<template>
  <div class="textarea" :class="{ 'form-group--error': validator && validator.$error }">
    <label v-if="label"> {{ label }} </label>
    <textarea class="ez-slim-scroll"
              ref="textarea"
              :placeholder="placeholder"
              :value="value" @input="updateText"
              :disabled="disabled"
              :style="{
                    '--bg': bg,
                    '--width': width,
                    '--height': height,
                    '--border': border,
                    '--border-color': borderColor,
                    '--border-radius': borderRadius,
                    '--padding': padding,
                    '--box-shadow': boxShadow,
                    '--font-size': fSize,
                    '--font-color': fColor,
                    '--placeholder-color': phColor,
                    '--placeholder-size': phFSize,
                }">
        </textarea>
    <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>
    <div v-if="validator && validator.$error">
      <span class="has-error animated headShake" v-if="!validator.required">{{ $t('field required') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    placeholder: {type: String, default: ''},
    change: {type: Function},
    width: {type: String, default: '100%'},
    value: [String, Number],
    validator: null,
    label: [String, Number],
    error: {type: String},
    disabled: {type: Boolean, default: false},
    bg: {type: String, default: '#f3f4fb'},
    height: {type: String, default: '95px'},
    border: {type: String, default: '1px'},
    borderRadius: {type: String, default: '.7rem'},
    borderColor: {type: String, default: '#E7E8F2'},
    padding: {type: String, default: '.3rem .5rem'},
    boxShadow: {type: String, default: '0 3px 12px'},
    fSize: {type: String, default: '1rem'},
    fColor: {type: String, default: '#495057'},
    phColor: {type: String, default: "#8798AD"},
    phFSize: {type: String, default: '1rem'},
  },
  methods: {
    updateText() {
      this.$emit('input', this.$refs.textarea.value);
      this.$emit('change', this.$refs.textarea.value);
      if (this.change) this.change(this.$refs.textarea.value);
    }
  }
}
</script>

<style scoped lang="scss">
.textarea {
  position: relative;
  width: 100%;
  height: 100%;

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black3);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  textarea {
    background: var(--bg);
    border: var(--border) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--font-color);
    min-height: var(--height);
    font-size: var(--font-size);
    padding: var(--padding);
    transition: .15s ease-in-out;
    width: var(--width);
    font-weight: $font-bold;
    resize: none;
    line-height: 21px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholder-color);
      font-size: var(--placeholder-size);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--placeholder-color);
      font-size: var(--placeholder-size);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholder-color);
      font-size: var(--placeholder-size);
    }

    &:focus {
      box-shadow: var(--box-shadow) rgba(color(bg-gray2), .3);
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}
</style>

<i18n scoped>
{
  "en": {
    "Invalid field": "Invalid field",
    "Required field": "Required field",
    "Invalid field, select at least one": "Invalid field, must select almost one",
    "required_field": "Required field"
  },
  "es": {
    "Invalid field": "Campo no válido",
    "Required field": "Campo obligatorio",
    "Invalid field, select at least one": "Campo no válido, debe seleccionar al menos una opción",
    "required_field": "Campo obligatorio"
  }
}
</i18n>
