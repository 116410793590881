import urlParams from "@/helper/urlParams";
import States from '@/helper/states';
import Countries from '@/helper/countries';
import Dh from '@/helper/date.helper';

import Validations from './validation';
import Errors from './Errors';
import Review from './Review/index';
import Complete from './Complete/index';

import FinancingService from '@/services/financing.service';

const service = new FinancingService();

export default {
    name: 'FinancingApplication',

    components: {
        Review,
        Complete
    },

    data() {
        return {
            step: 'PERSONAL_INFO',
            editing: false,

            acceptedTerms: false,
            PrivacyPolicyUrl: '',

            form: {
                FormType: 'FINANCIAL',
                Customer: null,
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Email: '',
                Phone: '',
                Birthday: '',
                SSN: '',
                ITIN: '',
                IdType: '',
                DriverLicense: '',
                DriverLicenseState: 'FL',
                Passport: '',
                Status: '',

                Address: '',
                City: '',
                Apt: '',
                State: 'FL',
                Zip: '',
                RentType: 'Rent',
                MonthlyPayment: '',
                LivedYears: '',
                LivedMonths: '',

                PreviousAddress: {
                    Country: 'US',
                    Address: '',
                    City: '',
                    Apt: '',
                    State: 'FL',
                    Zip: '',
                    MonthlyPayment: '',
                    RentType: 'Rent',
                    LivedYears: '',
                    LivedMonths: '',
                },

                Languages: [],

                EmploymentStatus: '',
                EmploymentEmployer: '',
                EmploymentTitle: '',
                EmploymentYears: '',
                EmploymentMonths: '',
                EmploymentPhone: '',
                GrossIncome: '',
                PaymentsType: 'W-2',
                SalaryPerMonth: '',

                PreviousEmployer: {
                    EmploymentStatus: '',
                    EmploymentEmployer: '',
                    EmploymentTitle: '',
                    EmploymentYears: '',
                    EmploymentMonths: '',
                    EmploymentPhone: '',
                    GrossIncome: '',
                    PaymentsType: 'W-2',
                    SalaryPerMonth: '',
                },

                DownPayment: '',
                CarMonthlyPayment: 0,
                Period: 72,

                TradeInCar: {
                    hasTradeIn: false,
                    Car: {
                        VIN: '',
                        Year: '',
                        Make: '',
                        Model: '',
                        BankInfo: '',
                        Mileage: '',
                        Color: '',
                        PayOff: ''
                    }
                },

                Tag: 'NEW',
                TagTransferStatus: 'ACTIVE',
                TagPlateNumber: '',
                Car: null,

                ReadyToBuy: false,
                AcceptedTerms: false
            },

            EmploymentStatuses: [
                {value: 'ACTIVE', text: this.$t('active')},
                {value: 'NOT APPLICABLE', text: this.$t('not_applicable')},
                {value: 'SELF-EMPLOYED', text: this.$t('self_employed')},
                {value: 'FULL TIME', text: this.$t('full_time')},
                {value: 'PART TIME', text: this.$t('part_time')},
                {value: 'CONTRACT', text: this.$t('contract')},
                {value: 'MILITARY', text: this.$t('military')},
                {value: 'RETIRED', text: this.$t('retired')},
                {value: 'TEMPORARY', text: this.$t('temporary')},
                {value: 'SEASONAL', text: this.$t('seasonal')}
            ],
            States: States,
            countries: Countries,
            TagTransferStatuses: [
                {value: 'ACTIVE', text: this.$t('active')},
                {value: 'PENDING', text: this.$t('pending')},
                {value: 'SLEEPING_METAL', text: this.$t('sleeping_metal')},
                {value: 'SLEEPING_NO_METAL', text: this.$t('sleeping_no_metal')},
            ],
            idTypes: [
                {value: 'us_license', text: this.$t('us_license')},
                {value: 'intl_license', text: this.$t('intl_license')},
                {value: 'state_id', text: this.$t('state_id')},
                {value: 'other_id', text: this.$t('other_id')},
            ],
            searchCar: '',

            isLoading: false,
            isLoadingForm: true,
            formErrors: false,
        }
    },

    validations: Validations,

    computed: {
        ...Errors,

        showPreviousAddress() {
            const years = parseInt(this.form.LivedYears);
            const months = parseInt(this.form.LivedMonths);

            if (Number.isNaN(years) && Number.isNaN(months)) return false;
            if (Number.isNaN(years)) return true;
            return years < 2;
        },

        showPreviousEmployer() {
            const years = parseInt(this.form.EmploymentYears);
            const months = parseInt(this.form.EmploymentMonths);

            if (Number.isNaN(years) && Number.isNaN(months)) return false;
            if (Number.isNaN(years)) return true;
            return years < 2;
        },

        maxBirthdayDate() {
            return Dh.customFormat();
        },

        showComplete() {
            return !(this.form.Status === 'new' || this.form.Status === 'customer_sent');
        },

        showSNNStep() {
            return !urlParams.has('agent') && this.form.Status === 'customer_sent';
        },

        showForm() {
            return !this.formErrors && !this.isLoadingForm;
        }
    },

    async mounted() {
        await this.getForm();

        this.parseForm();

        if (this.showSNNStep) {
            this.step = 'CONSENT';
        }

        this.isLoadingForm = false;
    },

    methods: {
        parseForm() {
            if (this.form.Birthday) {
                this.form.Birthday = Dh.customFormat(this.form.Birthday);
            }
            if (!this.form.DriverLicenseState) {
                this.form.DriverLicenseState = this.form.State;
            }
        },

        async getForm() {
            const res = await service.get(urlParams.get('form'));
            if (res && !res.error) {
                this.form = res.data.form;
            } else {
                console.error('This link has expired');
                this.formErrors = true;
            }
        },

        onStepEdit(step) {
            this.editing = true;
            this.step = step;
        },

        async submit() {
            await this.acceptTerms();
        },

        async acceptTerms() {
            this.isLoading = true;

            const res = await service.acceptTerms(this.form);
            if (res && !res.error) {
                this.isLoading = false;
                this.form.Status = 'terms_accepted';
                this.form.AcceptedTermsAt = new Date();
            }
        },

        onScrollTerms(e){
            if (e.target.scrollTop + e.target.clientHeight < (e.target.scrollHeight - 1) || this.acceptedTerms) {
                return;
            }
            this.acceptedTerms = true;
        },

        backStep() {
            if (this.editing) return;

            if (this.step === 'REVIEW') {
                this.step = 'FINANCE_INFO';

            } else if (this.step === 'FINANCE_INFO') {
                this.step = 'INCOME_INFO';

            } else if (this.step === 'INCOME_INFO') {
                this.step = 'ADDRESS_INFO';

            } else if (this.step === 'ADDRESS_INFO') {
                this.step = 'PERSONAL_INFO';

            }
        },

        nextStep() {
            this.$v.$touch();

            if (this.step === 'CONSENT') {
                if (!this.validPersonalInfo) return;
                this.step = 'REVIEW';
                this.$v.$reset();

            } else if (this.step === 'PERSONAL_INFO') {
                if (!this.validPersonalInfo) return;
                this.step = this.editing ? 'REVIEW' : 'ADDRESS_INFO';
                this.$v.$reset();

            } else if (this.step === 'ADDRESS_INFO') {
                if (!this.validAddress) return;
                this.step = this.editing ? 'REVIEW' : 'INCOME_INFO';
                this.$v.$reset();

            } else if (this.step === 'INCOME_INFO') {
                if (!this.validIncome) return;
                this.step = this.editing ? 'REVIEW' : 'FINANCE_INFO';
                this.$v.$reset();

            } else if (this.step === 'FINANCE_INFO') {
                if (!this.validFinance) return;
                this.step = 'REVIEW';
                this.$v.$reset();

            } else if (this.step === 'REVIEW') {
                this.step = 'CONSENT';

            }

            this.editing = false;
        },
    },
}