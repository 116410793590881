import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class AffiliateService extends IService {
    constructor(http) {
        super(http);
    }

    async create(form) {
        try {
            const response = await this.http.post(`${server_base}/api/affiliate/external`, form);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async get(id) {
        try {
            const response = await this.http.get(`${server_base}/api/affiliate/${id}/external`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async acceptTerms(id) {
        try {
            const response = await this.http.put(`${server_base}/api/affiliate/accept-terms`, {id});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default AffiliateService;