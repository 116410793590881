const url = new URL(window.location);

export default {
    has(param) {
       return url.searchParams.has(param);
    },

    get(param) {
        return url.searchParams.get(param);
    },

    set(param, value) {
        url.searchParams.set(param, value);
        window.history.pushState({}, '', url);
    },

    delete(param) {
        url.searchParams.delete(param);
        window.history.pushState({}, '', url);
    },
}