import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class AgentsService extends IService {
    constructor(http) {
        super(http);
    }


    async getAll() {
        try {
            const response = await this.http.get(`${server_base}/api/agent/agents-for-external-connect`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    // async getAgent(id) {
    //     try {
    //         const response = await this.http.put(`${server_base}/api/agents`, {id});
    //         return response.data;
    //     } catch (e) {
    //         return null;
    //     }
    // }
}

export default AgentsService;