import { render, staticRenderFns } from "./Referral.html?vue&type=template&id=38d55b2d&scoped=true&external"
import script from "./Referral.js?vue&type=script&lang=js&external"
export * from "./Referral.js?vue&type=script&lang=js&external"
import style0 from "./Referral.scss?vue&type=style&index=0&id=38d55b2d&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d55b2d",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FReferral%2Findex.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports