export default {
    set(key, value) {
        localStorage.setItem(key, value);
    },

    get(key) {
        return localStorage.getItem(key);
    },

    getSID() {
        return localStorage.getItem('ez_leads_sid');
    },

    setSID() {
        const sid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        localStorage.setItem('ez_leads_sid', sid);
        return sid;
    },

    hasSID() {
        return !(localStorage.getItem('ez_leads_sid') === null)
    },

    getCompany() {
        return JSON.parse(localStorage.getItem('ez_leads_forms_company'));
    },

    setCompany(company) {
        localStorage.setItem('ez_leads_forms_company', JSON.stringify({
            Logo: company.CompanySettings.Logo,
            Name: company.Name,
            InventoryUrl: company.InventoryUrl
        }));
    },

    setLang(value) {
        localStorage.setItem('ez_leads_forms_lang', value);
    },

    getLang() {
        return localStorage.getItem('ez_leads_forms_lang');
    },
};
