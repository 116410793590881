import {
    required, requiredIf, minLength, maxValue, email, numeric, alphaNum, between,
} from 'vuelidate/lib/validators';

import regexDriverLicense from '@/helper/regexDriverLicense';
import Dh from '@/helper/date.helper';

const // Custom validators
    isName = value => (/^([A-Za-z\s]+)$/i.test(value)),
    isSSN = value => (/^(?!000|666)[0-8][0-9]{2}-?(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$/.test(value)),
    isITIN = value => (/^9\d{2}-?((5[0-9]|6[0-5])|(8[3-8])|(9[0-2])|(9[4-9]))-?\d{4}$/.test(value)),
    isMobilePhone = value => (/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(value)),
    isPlateNumber = value => (/^[a-zA-Z0-9]*$/.test(value)),
    isZipCode = value => (/^\d{5}(-\d{4})?$/.test(value)),
    phoneIf = (value, vm) => (isMobilePhone || vm.EmploymentStatus === 'NOT APPLICABLE');

export default {
    form: {
        FirstName: {
            required,
            isName,
        },
        MiddleName: {
            validateIf: name => {
                return name ? isName(name) : true;
            },
        },
        LastName: {
            required,
            isName,
        },
        Email: {
            email,
            required
        },
        Phone: {
            isMobilePhone,
            required
        },
        Birthday: {
            validateIf: date => {
                return date ? Dh.isBefore(date, new Date()) : false;
            },
            required
        },
        SSN: {
            SsnIf: ssn => {
                return ssn ? isSSN(ssn) : true;
            },
            required: requiredIf(form => {
                return !(form.ITIN.length || form.Passport.length || form.DriverLicense.length);
            })
        },
        ITIN: {
            validateIf: itin => {
                return itin ? isITIN(itin) : true;
            },
            required: requiredIf(form => {
                return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
            })
        },
        Passport: {
            alphaNum,
            required: requiredIf(form => {
                return !(form.SSN.length || form.DriverLicense.length || form.DriverLicense.length);
            })
        },
        DriverLicenseState: {
            required: requiredIf(form => {
                return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
            })
        },
        DriverLicense: {
            validateIf: (dl, form) => {
                if (form.IdType === 'us_license') {
                    return dl ? regexDriverLicense[form.DriverLicenseState].rule.test(dl) : true;
                }
                else return true
            },
            required: requiredIf(form => {
                return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
            })
        },
        IdType: {
            required: requiredIf(form => {
                return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
            })
        },

        Address: {
            required,
            minLength: minLength(4),
        },
        City: {
            required,
            minLength: minLength(4),
        },
        State: {
            required,
            minLength: minLength(2),
        },
        Zip: {
            required,
            isZipCode,
        },
        MonthlyPayment: {
            required,
            numeric
        },
        LivedYears: {
            required: requiredIf(form => {
                return form.LivedMonths === '' || form.LivedMonths <= 3;
            }),
            numeric,
            maxValue: maxValue(100)
        },
        LivedMonths: {
            required: requiredIf(form => {
                return form.LivedYears === '' || form.LivedYears === '0';
            }),
            maxValue: maxValue(12),
        },

        PreviousAddress: {
            Country: {
                required: requiredIf(function () {
                    return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                }),
                minLength: minLength(2),
            },
            Address: {
                required: requiredIf(function (self) {
                    if (self.Country === 'US') {
                        return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                    }
                    return false;
                }),
                minLength: minLength(4),
            },
            City: {
                required: requiredIf(function (self) {
                    if (self.Country === 'US') {
                        return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                    }
                    return false;
                }),
                minLength: minLength(4),
            },
            State: {
                required: requiredIf(function (self) {
                    if (self.Country === 'US') {
                        return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                    }
                    return false;
                }),
                minLength: minLength(2),
            },
            Zip: {
                required: requiredIf(function (self) {
                    if (self.Country === 'US') {
                        return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                    }
                    return false;
                }),
                zipCodeIf: zip => {
                    return zip ? isZipCode(zip) : true;
                },
            },
            MonthlyPayment: {
                required: requiredIf(function (self) {
                    if (self.Country === 'US') {
                        return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
                    }
                    return false;
                }),
                numeric
            },
            LivedYears: {
                required: requiredIf(function (self) {
                    if (this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2) {
                        return self.LivedMonths === '' || self.LivedMonths <= 3;
                    } else return false;
                }),
                numeric,
                maxValue: maxValue(100),
            },
            LivedMonths: {
                required: requiredIf(function (self) {
                    if (this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2) {
                        return self.LivedYears === '' || self.LivedYears <= 3;
                    } else return false;
                }),
                maxValue: maxValue(12),
            },
            /*RentType: {
                required,
                alpha,
                minLength: minLength(2),
            },*/
        },

        EmploymentStatus: {
            required
        },
        EmploymentEmployer: {
            required: requiredIf(form => {
                return form.EmploymentStatus !== 'NOT APPLICABLE';
            }),
            minLength: minLength(2),
        },
        EmploymentTitle: {
            required: requiredIf(form => {
                return form.EmploymentStatus !== 'NOT APPLICABLE';
            }),
            minLength: minLength(2)
        },
        EmploymentPhone: {
            required: requiredIf(form => {
                return form.EmploymentStatus !== 'NOT APPLICABLE';
            }),
            phoneIf,
            minLength: minLength(10)
        },
        EmploymentYears: {
            required: requiredIf(form => {
                return form.EmploymentStatus !== 'NOT APPLICABLE' && (form.EmploymentMonths === '' || form.EmploymentMonths <= 3);
            }),
            numeric,
            maxValue: maxValue(50)
        },
        EmploymentMonths: {
            required: requiredIf(form => {
                return form.EmploymentStatus !== 'NOT APPLICABLE' && (form.EmploymentYears === '' || form.EmploymentYears === '0');
            }),
            numeric,
            maxValue: maxValue(12)
        },
        GrossIncome: {
            required,
            numeric
        },
        SalaryPerMonth: {
            numeric,
        },

        PreviousEmployer: {
            EmploymentStatus: {
                required: requiredIf(function () {
                    return this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2;
                })
            },
            EmploymentEmployer: {
                required: requiredIf(function (self) {
                    if (this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2) {
                        return self.EmploymentStatus !== 'NOT APPLICABLE';
                    }
                    return false;
                }),
                minLength: minLength(2),
            },
            EmploymentTitle: {
                required: requiredIf(function (self) {
                    if (this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2) {
                        return self.EmploymentStatus !== 'NOT APPLICABLE';
                    }
                }),
                minLength: minLength(2),
            },
            EmploymentYears: {
                required: requiredIf(function (self) {
                    if (this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2) {
                        return self.EmploymentStatus !== 'NOT APPLICABLE' && (self.EmploymentMonths === '' || self.EmploymentMonths <= 3);
                    }
                }),
                numeric,
                maxValue: maxValue(50)
            },
            EmploymentMonths: {
                required: requiredIf(function (self) {
                    if (this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2) {
                        return self.EmploymentStatus !== 'NOT APPLICABLE' && (self.EmploymentYears === '' || self.EmploymentYears === '0');
                    }
                }),
                numeric,
                maxValue: maxValue(12)
            },
            EmploymentPhone: {
                required: requiredIf(function (self) {
                    if (this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2) {
                        return self.EmploymentStatus !== 'NOT APPLICABLE';
                    }
                }),
                phoneIf,
                minLength: minLength(10)
            },
            GrossIncome: {
                required: requiredIf(function () {
                    return this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2;
                }),
                numeric
            },
            SalaryPerMonth: {
                numeric
            },
        },

        DownPayment: {
            numeric
        },
        CarMonthlyPayment: {
            numeric
        },
        Period: {
            required,
            numeric,
            between: between(24, 72)
        },
        TagPlateNumber: {
            plateNumber: plate => {
                return plate ? isPlateNumber(plate) : true;
            }
        },
    }
}