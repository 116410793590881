import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class ReferralService extends IService {
    constructor(http) {
        super(http);
    }

    async create(form) {
        try {
            const response = await this.http.post(`${server_base}/api/referral/external`, form);
            return response.data;
        } catch (e) {
            return null;
        }
    }


    async acceptTerms(id) {
        try {
            const response = await this.http.put(`${server_base}/api/referral/accept-terms`, {id});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default ReferralService;
