import moment from "moment";

export default {
    customFormat(date, format) {
        if (!date) {
            return moment().format(format || "YYYY-MM-DD");
        }
        return moment(date).format(format || "YYYY-MM-DD");
    },

    isBefore(date, before) {
        try {
            return moment(date).isBefore(before, 'minutes');
        } catch (e) {
            return false;
        }
    }
}