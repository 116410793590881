export default {
    EmailToError() {
        if (!this.$v.EmailTo.$anyError) return null;
        else if (!this.$v.EmailTo.required) return "required_field";
        else if (this.$v.EmailTo.$invalid) return "invalid_field";
        else return null;
    },
    PhoneToError() {
        if (!this.$v.PhoneTo.$anyError) return null;
        else if (!this.$v.PhoneTo.required) return "required_field";
        else if (this.$v.PhoneTo.$invalid) return "invalid_field";
        else return null;
    },
}
