
import AgentsService from '@/services/agents.service';
import config from "@/config";
import urlParams from "@/helper/urlParams";

const service = new AgentsService();
const server_base = config[config.stage].ws_server;

export default {
    name: 'teams',
    components: {
    },
    data() {
        return {
            teams: [],
            tenant: ''
        }
    },
    computed: {

    },
    async mounted() {
        const { data } = await service.getAll();
        this.teams = data;

        this.tenant = urlParams.get('tenant');

        console.log(this.teams)
    },
    methods: {
        setRole(role) {
            const ROLES = {
                "seller": "Our Sales Team",
                "bdc": "Our BDC Team",
            }

            return role && ROLES[role] ? ROLES[role] : 'Unknown'
        },
        userPhoto(agent) {
            return `${server_base}/api/files/avatar/${agent}`;
        },
        goToBusinessCard(agentId) {
            const path = `?tenant=${this.tenant}&agent=${agentId}&formType=business_card`
            window.location.href = path;
        },
        goToAffiliate(agentId) {
            const path = `?tenant=${this.tenant}&agent=${agentId}&formType=affiliate`
            window.location.href = path;
        }
    },
}


