import IService from './iservice';
import config from "../config";

const inv_server_base = config[config.stage].inventory_server;

class InventoryService extends IService {
    constructor(http){
        super(http);
    }

    async getByVin(vin) {
        try {
            const res = await this.http.get(`${inv_server_base}/api/inventory/${vin}`);
            return res.data;
        } catch (e) {
            return null;
        }
    }

    async getCarList(limit = '', skip = '', query = {}, allowRemoved = false) {
        try {
            const response = await this.http.get(`${inv_server_base}/api/car/external?limit=${limit}&skip=${skip}&allowremoved=${allowRemoved}`, query);
            return response.data;
        } catch (e) {
            return null
        }
    }
}

export default InventoryService;