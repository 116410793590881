import {required, email} from 'vuelidate/lib/validators';

// import regexDriverLicense from '@/helper/regexDriverLicense';
// import Dh from '@/helper/date.helper';

const // Custom validators
    isName = value => (/^([A-Za-z\s]+)$/i.test(value)),
    // isSSN = value => (/^(?!000|666)[0-8][0-9]{2}-?(?!00)[0-9]{2}-?(?!0000)[0-9]{4}$/.test(value)),
    isMobilePhone = value => (/^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(value));

export default {
    form: {
        FirstName: {
            required,
            isName,
        },
        MiddleName: {
            validateIf: name => {
                return name ? isName(name) : true;
            },
        },
        LastName: {
            required,
            isName,
        },
        Email: {
            email,
            required
        },
        Phone: {
            isMobilePhone,
            required
        },
        // Birthday: {
        //     validateIf: date => {
        //         return date ? Dh.isBefore(date, new Date()) : false;
        //     },
        //     required
        // },
        // SSN: {
        //     SsnIf: ssn => {
        //         return ssn ? isSSN(ssn) : true;
        //     },
        //     required: requiredIf(form => {
        //         return !(form.ITIN.length || form.Passport.length || form.DriverLicense.length);
        //     })
        // },
        // Passport: {
        //     alphaNum,
        //     required: requiredIf(form => {
        //         return !(form.SSN.length || form.DriverLicense.length || form.DriverLicense.length);
        //     })
        // },
        // DriverLicenseState: {
        //     required: requiredIf(form => {
        //         return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
        //     })
        // },
        // DriverLicense: {
        //     validateIf: (dl, form) => {
        //         if (form.IdType === 'us_license') {
        //             return dl ? regexDriverLicense[form.DriverLicenseState].rule.test(dl) : true;
        //         } else return true
        //     },
        //     required: requiredIf(form => {
        //         return !(form.SSN.length || form.Passport.length || form.IdType.length || form.DriverLicense.length);
        //     })
        // }
    }
}
