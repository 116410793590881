import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocalStore from './LocalStore';

Vue.use(VueI18n);

let local_lang = LocalStore.getLang();
const browser_lang = navigator.language.substr(0, 2);

if ( !local_lang ) {
    LocalStore.setLang(browser_lang);
    local_lang = browser_lang;
}

export const i18n = new VueI18n({
    locale: local_lang,
    fallbackLocale: 'en',
    messages: {
        en: {
        },
        es: {
        }
    }
});