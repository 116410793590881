import Validations from './validation';
import Errors from './Errors';
import Terms from '../Terms/index';
import urlParams from '@/helper/urlParams';

import AffiliateService from '@/services/affiliate.service';
const service = new AffiliateService();

export default {
    name: 'affiliate',
    components: {
        Terms
    },
    props: [],
    data() {
        return {
            step: 'form',
            formId: null,
            form: {
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Email: '',
                Phone: '',
                Birthday: '',
                SSN: '',
                DriverLicense: '',
                DriverLicenseState: 'FL',
                TermsAccepted: false,
            }
        }
    },
    validations: Validations,
    computed: {
        ...Errors
    },
    mounted() {
        if (urlParams.has('id')) {
            this.formId = urlParams.get('id');
            this.step = 'terms';
        }

        if (urlParams.has('phone')) {
            this.form.Phone = urlParams.get('phone');
        }

        if (urlParams.has('agent')) {
            this.form.Agent = urlParams.get('agent');
        }
    },
    methods: {
        async create() {
            const res = await service.create(this.form);
            if (res && !res.error) {
                this.step = 'completed';
            }
        },

        async acceptTerms() {
            const res = await service.acceptTerms(this.formId);
            if (res && !res.error) {
                this.step = 'completed';
            }
        },

        async nextToTerms() {
            await this.$v.$touch();
            if (this.$v.$invalid) return;

            this.step = 'terms';
        },

        termsAccepted(terms) {
            if (terms && !this.form.TermsAccepted) this.form.TermsAccepted = true;
        }
    }
}


