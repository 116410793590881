export default {
    FirstNameError() {
        if (!this.$v.form.FirstName.$anyError) return null;
        else if (!this.$v.form.FirstName.required) return "required_field";
        else if (this.$v.form.FirstName.$invalid) return "invalid_field";
        else return null;
    },
    MiddleNameError() {
        if (!this.$v.form.MiddleName.$anyError) return null;
        else if (this.$v.form.MiddleName.$invalid) return "invalid_field";
        else return null;
    },
    LastNameError() {
        if (!this.$v.form.LastName.$anyError) return null;
        else if (!this.$v.form.LastName.required) return "required_field";
        else if (this.$v.form.LastName.$invalid) return "invalid_field";
        else return null;
    },
    EmailError() {
        if (!this.$v.form.Email.$anyError) return null;
        else if (!this.$v.form.Email.required) return "required_field";
        else if (this.$v.form.Email.$invalid) return "invalid_field";
        else return null;
    },
    PhoneError() {
        if (!this.$v.form.Phone.$anyError) return null;
        else if (!this.$v.form.Phone.required) return "required_field";
        else if (this.$v.form.Phone.$invalid) return "invalid_field";
        else return null;
    },
}
