class Utils {
    static FixCase(string) {
        return string.charAt(0).toUpperCase() + string.toLower().slice(1);
    }

    static HighlightText(text, matched) {
        if (text && !matched) {
            return text;
        }

        if (text && matched) {
            const regExp = new RegExp(matched, "gi");
            return text.toString().replace(regExp, matchedText => {
                return `<span style="background:#fef5ce;border-radius:3px;font-weight:500">${matchedText}</span>`;
            });
        }

    }

    static ParseValidations(vForm, Validations) {
        let error = false;
        let data = {};

        for (let key in vForm) {
            if (/^\$/.test(key))
                continue;

            const $el = vForm[key] !== undefined && vForm[key].$invalid !== undefined &&
            vForm[key].$invalid === true ? vForm[key] : undefined;

            if ($el) {
                error = true;

                if ($el.required !== undefined && !$el.required)
                    data[key] = Validations.errors[key].required;
                else {
                    for (let [k, val] of Object.entries($el)) {
                        if (!/^\$/.test(k)) {
                            if (typeof val === "object")
                                for (let [subk, subval] of Object.entries(val)) {
                                    if (!/^\$/.test(subk) && !subval)
                                        data[k] = Validations.errors[key][k][subk];
                                }
                            else if (!val)
                                data[key] = Validations.errors[key][k];
                        }
                    }
                }
            }
        }

        return {error, data};


    }

    static ConvertToMiliseconds(value, unit) {
        const lib = {
            SECOND: 1000,
            MINUTE: 60000,
            HOUR: 3.6e+6,
            DAY: 8.64e+7,
            WEEK: 6.048e+8,
            MONTH: 2.628e+9,
            YEAR: 3.154e+10
        }
        if (lib[unit])
            return lib[unit] * value;
        return 0;
    }

    static ConvertToUnit(miliseconds, unit) {
        const lib = {
            SECOND: 1000,
            MINUTE: 60000,
            HOUR: 3.6e+6,
            DAY: 8.64e+7,
            WEEK: 6.048e+8,
            MONTH: 2.628e+9,
            YEAR: 3.154e+10
        }
        if (lib[unit])
            return miliseconds / lib[unit];
        return 0;
    }

    static convertDateToUTC(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    static MonthlyPayment(apr, years, down, cost) {
        try {
            let r = (apr / 12) / 100;
            let n = years * 12;
            let d = down;
            let p = cost - d;
            let per_month = p * ((r * Math.pow((1 + r), n)) / (Math.pow((1 + r), n) - 1));
            return per_month.toFixed(0);
        } catch (e) {
            return null;
        }
    }

    static NormalizeNumber(text) {
        let number = text.replace(/\(|\)/g, "");
        number = number.replace(/\./g, "");
        number = number.replace(/-/g, "");
        number = number.replace(/ /g, "");
        number = number.slice(-10);
        return number;
    }

    static isUsPhone(val)  {
        if (val && val.length > 0) {
            return /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/.test(val);
        }
        return false;
    }

    static formatUSNumber(entry = '') {
        const match = entry
            .replace(/\D+/g, '').replace(/^1/, '')
            .match(/([^\d]*\d[^\d]*){1,10}$/)[0];

        const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';

        return `${part1}${part2}${part3}`;
    }

    static FormatFileSizeMetric(fileSize) {
        let size = Math.abs(fileSize);

        if (Number.isNaN(size)) {
            return 'Invalid file size';
        }
        if (size === 0) {
            return '0 bytes';
        }

        const units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
        let quotient = Math.floor(Math.log10(size) / 3);
        quotient = quotient < units.length ? quotient : units.length - 1;
        size /= (1000 ** quotient);

        return `${+size.toFixed(1)} ${units[quotient]}`;
    }

    static LongestArrayOfMatrix(matrix) {
        let longest = -1;

        for (let i = 0; i < matrix.length; i++) {
            if (longest === -1 || matrix[i][0].length > matrix[longest][0].length) {
                longest = i;
            }
        }

        return matrix[longest][0];
    }
}

export default Utils;

export function validateEmail(email) {
    const validator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validator.test(String(email).toLowerCase());
}

export function getDistanceValue(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return (longerLength - Distance(longer, shorter)) / parseFloat(longerLength);
}

function Distance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    let newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export function Levenshtein(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    let matrix = [];

    // increment along the first column of each row
    let i;
    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    // increment each column in the first row
    let j;
    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                    Math.min(matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j] + 1)); // deletion
            }
        }
    }

    return matrix[b.length][a.length];
}
