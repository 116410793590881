import Dh from '@/helper/date.helper';
import Countries from '@/helper/countries';

export default {
    name: 'FinancingApplicationReview',

    props: {
        step: {type: String, default: 'REVIEW'},
        form: Object
    },

    data() {
        return {
            countries: Countries,
        }
    },

    computed: {
        showPreviousAddress() {
            return this.form.LivedYears && this.form.LivedYears >= 0 && this.form.LivedYears < 2;
        },

        showPreviousEmployer() {
            return this.form.EmploymentYears && this.form.EmploymentYears >= 0 && this.form.EmploymentYears < 2;
        },

        maxBirthdayDate() {
            return Dh.customFormat();
        },

        country() {
            const index = this.countries.findIndex(country => country.abbreviation === this.form.PreviousAddress.Country)
            if (index > -1) {
                return this.countries[index].name
            }
            return 'N/A';
        },
    },

    methods: {
        edit(step) {
            this.$emit('stepEdit', step)
        },
    },

    filters: {
        Birthday(date) {
            return Dh.customFormat(date) || 'N/A';
        }
    }
}