<template>
  <div>
    <svg v-if="name === 'facebook'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M22 12C22 16.9964 18.3357 21.1369 13.5479 21.8809C13.2558 21.9263 13 21.6942 13 21.3985V14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H13V10C13 9.44772 13.4477 9 14 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H14C12.3431 7 11 8.34315 11 10V12H10C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H11V21.3985C11 21.6942 10.7442 21.9263 10.4521 21.8809C5.66427 21.1369 2 16.9964 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            :fill="fillColor"/>
    </svg>

    <svg v-if="name === 'linkedIn'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8ZM7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V12ZM8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9ZM14 13C13.4477 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14V12C11 11.4477 11.4477 11 12 11C12.2759 11 12.5257 11.1117 12.7066 11.2924C13.0982 11.105 13.5369 11 14 11C15.6569 11 17 12.3431 17 14V16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16V14C15 13.4477 14.5523 13 14 13Z"
            :fill="fillColor"/>
    </svg>

    <svg v-if="name === 'messenger'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.2512 2.74389 16.3287 3.99927 18L3.99977 20.7708C3.99989 21.4049 4.58261 21.8788 5.20344 21.7497L8 21.1679C9.22492 21.7031 10.5778 22 12 22ZM6.70509 13.707L9.45568 9.7776C9.76078 9.34174 10.3537 9.22109 10.8049 9.50307L13.4525 11.1578C13.7858 11.3661 14.2102 11.3599 14.5372 11.1419L16.7092 9.69385C16.8783 9.58113 17.1034 9.60343 17.2471 9.74711C17.3934 9.89337 17.4135 10.1235 17.2949 10.293L14.5443 14.2224C14.2392 14.6583 13.6463 14.7789 13.1951 14.4969L10.5475 12.8422C10.2142 12.6339 9.78981 12.6401 9.4628 12.8581L7.29077 14.3062C7.1217 14.4189 6.89657 14.3966 6.75289 14.2529C6.60663 14.1066 6.58647 13.8765 6.70509 13.707Z"
            :fill="fillColor"/>
    </svg>

    <svg v-if="name === 'twitter'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.9999 8.00175C19.9999 8.00117 19.9999 8.00058 19.9999 8C19.9999 7.99994 19.9999 7.99988 19.9999 7.99982L21.875 5.97672C22.1806 5.64695 21.9057 5.11563 21.46 5.17458L19.1108 5.48528C18.3775 4.57926 17.2563 4 15.9999 4C13.7908 4 11.9999 5.79086 11.9999 8C11.9999 8.52744 12.102 9.03104 12.2875 9.49212C9.29113 9.42205 6.08285 8.82913 4.4608 5.6154C4.26009 5.21773 3.68469 5.1947 3.55692 5.62143C2.38319 9.54144 4.4488 14.6347 8.99973 16C7.86444 17.11 4.94607 17.4114 2.55785 17.1927C2.09312 17.1502 1.80137 17.6796 2.16075 17.9773C4.16328 19.6361 6.94066 20 9.49981 20C15.9991 20 20.4989 14.501 19.9999 8.00175Z"
            :fill="fillColor"/>
    </svg>

    <svg v-if="name === 'youtube'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.58971 4.20941C3.72849 4.33555 2.29215 5.81694 2.1711 7.6785C2.08072 9.06851 2 10.6796 2 12C2 13.3204 2.08072 14.9315 2.1711 16.3215C2.29215 18.1831 3.72849 19.6645 5.58971 19.7906C7.21433 19.9007 9.40027 20 12 20C14.5997 20 16.7857 19.9007 18.4103 19.7906C20.2715 19.6645 21.7078 18.1831 21.8289 16.3215C21.9193 14.9315 22 13.3204 22 12C22 10.6796 21.9193 9.06851 21.8289 7.6785C21.7078 5.81694 20.2715 4.33555 18.4103 4.20941C16.7857 4.09931 14.5997 4 12 4C9.40027 4 7.21433 4.09931 5.58971 4.20941ZM11.5521 9.16779C10.8873 8.72764 10 9.20432 10 10.0016V13.9987C10 14.7959 10.8873 15.2726 11.552 14.8325L14.5454 12.8507C15.1546 12.4474 15.1547 11.5531 14.5455 11.1498L11.5521 9.16779Z"
            :fill="fillColor"/>
    </svg>

    <svg v-if="name === 'instagram'" width="35" height="35" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3 7C3 4.79086 4.79086 3 7 3H17C19.2091 3 21 4.79086 21 7V17C21 19.2091 19.2091 21 17 21H7C4.79086 21 3 19.2091 3 17V7ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12ZM17.5 7.5C18.0523 7.5 18.5 7.05228 18.5 6.5C18.5 5.94772 18.0523 5.5 17.5 5.5C16.9477 5.5 16.5 5.94772 16.5 6.5C16.5 7.05228 16.9477 7.5 17.5 7.5Z"
            :fill="fillColor"/>
    </svg>


  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {String},
    fillColor: {String, default:'#000'}
  }
}
</script>

<style scoped>

</style>