var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"check",class:{
                'check-box': _vm.type === 'box',
                'check-box-pill': _vm.type === 'pill',
                'check-box-switch': _vm.type === 'switch',
                'inline': _vm.inline,
                'show-text':_vm.text !== ''
              },style:({
                '--text-width': _vm.textWidth,
                '--font-size': _vm.fSize,
                '--font-color': _vm.fColor
                })},[_c('input',{ref:"checkbox",staticClass:"input",attrs:{"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":_vm.value},on:{"input":_vm.updateCheck,"change":function($event){return _vm.$emit('change')},"click":function($event){return _vm.$emit('click', $event)}}}),(_vm.type === 'box' || _vm.type === 'switch')?_c('div',{staticClass:"checkbox"},[(_vm.type === 'switch')?_c('span',{staticClass:"on"},[_vm._v("ON")]):_vm._e(),(_vm.type === 'switch')?_c('span',{staticClass:"off"},[_vm._v("OFF")]):_vm._e()]):_vm._e(),(_vm.text !== '')?_c('div',{staticClass:"checkbox-text"},[_vm._v(_vm._s(_vm.text))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }